import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Card, CardBody, Col, Row } from "reactstrap"
import { handleValidDate } from "constants/common"
import { axiosInstance } from "ConfigAxioxinstance"
import Loader from "common/Loader"

const BenposProjectCard = ({
  investmentId,
  benpos_date,
  startDate,
  endDate,
  onLoadComplete = data => {},
}) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)

  const getInvestmentDetails = async () => {
    try {
      setLoading(true)
      const response = await axiosInstance.get(`/investment?id=${investmentId}`)
      onLoadComplete(response?.data?.data[0])
      setData(response?.data?.data[0] || response?.data[0])
    } catch (error) {
      console.error("Error fetching investment details:", error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getInvestmentDetails()
  }, [])

  const fields = [
    { label: "Interest Payout Date", value: data?.interest_payout_date },
  ]

  if (startDate) {
    fields.push({ label: "Start Date", value: startDate })
  }
  if (endDate) {
    fields.push({ label: "End Date", value: endDate })
  }

  if (!startDate && !endDate) {
    fields.push(
      { label: "Ex-Interest Date", value: data?.ex_interest_date },
      { label: "Record Date", value: data?.record_date },
      { label: "Benpos Date", value: benpos_date }
    )
  }

  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <Card>
          <CardBody>
            {data && (
              <Row className="d-flex justify-content-between align-items-center">
                {data.registered_issuer_name && (
                  <Col md={4} className="text-left">
                    <h4 className="mb-2 fw-bold">{data.project_name}</h4>
                    <p className="mb-1 text-muted">
                      {data.registered_issuer_name}
                    </p>
                  </Col>
                )}

                <Col
                  md={8}
                  className={`d-flex ${
                    fields.length === 6 ? "flex-wrap" : ""
                  } justify-content-between align-items-center`}
                >
                  {fields.map((field, index) => (
                    <div
                      key={field.label}
                      className={`text-left ${
                        fields.length === 6
                          ? "col-12 col-md-4 mb-3"
                          : "col-12 col-md-3"
                      }`}
                    >
                      <p className="mb-1 text-muted">{field.label}</p>
                      <h5 className="mb-0">
                        {field.value ? handleValidDate(field.value) : "NA"}
                      </h5>
                    </div>
                  ))}
                </Col>
              </Row>
            )}
          </CardBody>
        </Card>
      )}
    </>
  )
}

BenposProjectCard.propTypes = {
  investmentId: PropTypes.string,
  benpos_date: PropTypes.string,
  onLoadComplete: PropTypes.func,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
}

export default BenposProjectCard
