import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Link } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import Loader from "common/Loader"
import ReactSelect from "constants/ReactSelect"
import { toast } from "react-toastify"
import { axiosInstance } from "ConfigAxioxinstance"
import { csvDownloadData, handleValidDate } from "constants/common"
import { JSONToCSVConvertor } from "common/jsontocsv"
import nodata from "../../assets/images/nodata/no-data.svg"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { permissionChecker } from "common/PermissionChecker"
import Unauthorized from "common/Unauthorized"
import BenposProjectCard from "components/Common/BenposProjectCard"
import AuditTrail from "common/AuditTrail/AuditTrail"
import { useParams } from "react-router-dom"
import HeaderWithBackBtn from "components/Common/HeaderWithBackBtn"
import AddRemarkBtn from "components/Remark/AddRemarkBtn"

const leadingZeroOptions = [
  { id: 0, value: "", label: "No Zeros" },
  { id: 1, value: "0", label: "1 Zero" },
  { id: 2, value: "00", label: "2 Zeros" },
  { id: 3, value: "000", label: "3 Zeros" },
  { id: 4, value: "0000", label: "4 Zeros" },
  { id: 5, value: "00000", label: "5 Zeros" },
  // { id: 6, value: "000000", label: "6 Zeros" },
]

const ExceptionFilter = [
  {
    id: 1,
    label: "IFSC Missing",
    endpoint: "exception?category=COMMON&category=BENPOS&ifsc=null",
  },
  {
    id: 2,
    label: "IFSC Not Matched",
    endpoint: "exception?category=COMMON&category=BENPOS&ifsc_matched=false",
  },
  {
    id: 3,
    label: "Account Number Not Matched",
    endpoint:
      "exception?category=COMMON&category=BENPOS&bank_account_matched=false",
  },
  {
    id: 3,
    label: "Account Number Matched",
    endpoint:
      "exception?category=COMMON&category=BENPOS&bank_account_matched=true",
  },
]

const ExceptionReport = () => {
  const [loading, setLoading] = useState(false)
  const [tableData, setTableData] = useState([])
  const [reportCountData, setReportCountData] = useState([])
  const [selectedReport, setSelectedReport] = useState()
  const [editIfscModal, setEditIfscModal] = useState(false)
  const [selectedData, setSelectedData] = useState()
  const [manualIfcs, setManualIfsc] = useState("")
  const [proofAttachment, setProofAttachment] = useState()
  const [leadingZero, setLeadingZero] = useState(leadingZeroOptions[0])
  const [selectedExceptionFilter, setSelectedExceptionFilter] = useState(null)
  const [selectedLogId, setSelectedLogId] = useState("")
  const [auditTrailModal, setAuditTrailModal] = useState(false)
  const [isApproved, setIsApproved] = useState(false)

  const [accessControlData, setAccessControlData] = useState([])

  const params = useParams()
  const { benposdate, investmentId } = params

  useEffect(() => {
    const permissions = permissionChecker("/benpos-master")
    setAccessControlData(permissions)
  }, [])

  const defaultSorted = [
    {
      dataField: "created_at",
      order: "desc",
    },
  ]

  const toggleIFSCModal = async () => {
    setEditIfscModal(!editIfscModal)
  }

  const handleOrderClick = async data => {
    setSelectedData(data)
    const leadingZeroCount =
      data?.manual_account_number?.match(/^0*/)[0]?.length || 0
    setLeadingZero(leadingZeroOptions[leadingZeroCount])
    if (data.bank_account_number == data.user_account_number) {
      setManualIfsc(data.user_ifsc_code || "")
    }
    setManualIfsc(data?.manual_ifsc_code || data.bank_ifsc)
    toggleIFSCModal()
  }

  const getSignedUrlHandler = async file => {
    try {
      const response = await axiosInstance.post("/get-signed-url", {
        fileName: file,
      })
      return response?.data
    } catch (error) {
      throw error
    }
  }

  const DocumentOpenHandler = async link => {
    const url = await getSignedUrlHandler(link)
    window.open(url)
  }

  const tableColumns = (download = false) => {
    let columns = []
    if (
      download == false &&
      selectedReport?.id === "common-benpos-bank-details"
    ) {
      columns.push({
        dataField: "",
        isDummyField: true,
        text: "Edit",
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, order) => {
          if (order?.bank_account_matched && !order?.bank_ifsc) {
            return "NA"
          } else {
            return !isApproved ? (
              <div className="d-flex gap-3" style={{ cursor: "pointer" }}>
                <div
                  className="text-success"
                  onClick={() => handleOrderClick(order)}
                >
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id={`edittooltip-${order?.id}`}
                  />
                  <UncontrolledTooltip
                    placement="top"
                    target={`edittooltip-${order?.id}`}
                  >
                    Edit
                  </UncontrolledTooltip>
                </div>
              </div>
            ) : (
              "NA"
            )
          }
        },
      })
    }
    columns.push(
      {
        dataField: "investor_name",
        text: "Investor Name",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => {
          return (
            <span>
              {selectedReport?.id == "system-entries"
                ? row.name_on_pan
                : cellContent}
            </span>
          )
        },
      },
      {
        dataField: "category",
        text: "Category",
        sort: true,
      }
    )
    if (selectedReport?.id === "common-benpos-bank-details") {
      columns.push(
        {
          dataField: "bank_ifsc",
          text: "IFSC (as per BenPos)",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (benpos_ifsc, row) => {
            if (row.manual_ifsc_code) {
              return (
                <span
                  className={`text-end d-flex align-items-center text-primary`}
                >
                  {row.manual_ifsc_code}
                  <i
                    className="mdi mdi-information-outline font-size-16 ms-2 text-secondary"
                    id={`ifsctooltip-${row.id}`}
                    style={{ cursor: "pointer" }}
                  />
                  <UncontrolledTooltip target={`#ifsctooltip-${row.id}`}>
                    Replaced with Manually added IFSC Code
                  </UncontrolledTooltip>
                </span>
              )
            }

            if (benpos_ifsc) {
              return benpos_ifsc
            }

            if (row.bank_account_number === row.user_account_number) {
              return (
                <span className="text-primary">
                  {row.user_ifsc_code}
                  <i
                    className="mdi mdi-information-outline font-size-16 ms-2 text-secondary"
                    id={`ifsctooltip-${row.id}`}
                    style={{ cursor: "pointer" }}
                  />
                  <UncontrolledTooltip target={`#ifsctooltip-${row.id}`}>
                    Replaced with IFSC (as per System) as Account Number Matched
                    in both BenPos & System
                  </UncontrolledTooltip>
                </span>
              )
            }
            return benpos_ifsc
          },
        },
        {
          dataField: "user_ifsc_code",
          text: "IFSC (as per System)",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cellContent, row) => {
            return <span className={`text-end d-block`}>{cellContent}</span>
          },
        },
        {
          dataField: "bank_ifsc_exception",
          text: "IFSC Exception",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cellContent, row) => {
            return (
              <span
                className={`badge badge-pill font-size-12 badge-soft-${
                  row.bank_ifsc
                    ? row.ifsc_matched
                      ? "success"
                      : "danger"
                    : "warning"
                }`}
              >
                {row.bank_ifsc
                  ? row.ifsc_matched
                    ? "IFSC Matched"
                    : "IFSC Not Matched"
                  : "IFSC Missing"}
              </span>
            )
          },
        },
        {
          dataField: "bank_account_number",
          text: "Account Number (as per BenPos)",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cellContent, row) => {
            if (row.manual_account_number) {
              return (
                <span
                  className={`text-end d-flex justify-content-end align-items-center text-primary`}
                >
                  {row.manual_account_number}
                  <i
                    className="mdi mdi-information-outline font-size-16 ms-2 text-secondary"
                    id={`bankAccounttooltip-${row.id}`}
                    style={{ cursor: "pointer" }}
                  />
                  <UncontrolledTooltip target={`#bankAccounttooltip-${row.id}`}>
                    Replaced with Manually added Account Number
                  </UncontrolledTooltip>
                </span>
              )
            }
            return <span className={`text-end d-block`}>{cellContent}</span>
          },
        },
        {
          dataField: "user_account_number",
          text: "Account Number (as per System)",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cellContent, row) => {
            return <span className={`text-end d-block`}>{cellContent}</span>
          },
        },
        {
          dataField: "bank_account_number_exception",
          text: "Account Number Exception",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cellContent, row) => {
            return (
              <span
                className={`badge badge-pill font-size-12 badge-soft-${
                  row.bank_account_number == row.user_account_number
                    ? "success"
                    : "danger"
                }`}
              >
                {row.bank_account_number == row.user_account_number
                  ? "Account Number Matched"
                  : "Account Number Not Matched"}
              </span>
            )
          },
        },
        {
          dataField: "bank_name",
          text: "Bank Name (as per BenPos)",
          sort: true,
        },

        {
          dataField: "bank_update_proof_file",
          text: "Uploaded Proof",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cellContent, row) => {
            if (!cellContent) {
              return null
            }
            return (
              <div
                onClick={() => DocumentOpenHandler(cellContent)}
                style={{ cursor: "pointer" }}
              >
                <svg viewBox="0 0 24 24" width="26px" fill={"#556ee6"}>
                  <path d="M6,2A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6M6,4H13V9H18V20H6V4M8,12V14H16V12H8M8,16V18H13V16H8Z" />
                </svg>
              </div>
            )
          },
        },
        {
          dataField: "",
          text: "Logs",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cellContent, row) => {
            return (
              <>
                <Link
                  to="#"
                  onClick={() => {
                    setSelectedLogId(row.benpos_id)
                    setAuditTrailModal(!auditTrailModal)
                  }}
                >
                  <i
                    className="mdi mdi-content-paste font-size-18"
                    id="logsToolTip"
                  />
                </Link>
                <UncontrolledTooltip placement="top" target="logsToolTip">
                  Logs
                </UncontrolledTooltip>
              </>
            )
          },
        }
      )
    } else if (selectedReport?.id === "system-entries") {
      columns.push(
        {
          dataField: "user_ifsc_code",
          text: "IFSC (as per System)",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cellContent, row) => {
            return <span>{cellContent}</span>
          },
        },
        {
          dataField: "user_account_number",
          text: "Account Number (as per System)",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cellContent, row) => {
            return <span className={`text-end d-block`}>{cellContent}</span>
          },
          // eslint-disable-next-line react/display-name
          headerFormatter: (column, colIndex) => (
            <div className="text-end">{column.text}</div>
          ),
        },
        {
          dataField: "lot_size",
          text: "Lot Size (as per System)",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cellContent, row) => {
            return <span className={`text-end d-block`}>{cellContent}</span>
          },
          // eslint-disable-next-line react/display-name
          headerFormatter: (column, colIndex) => (
            <div className="text-end">{column.text}</div>
          ),
        },

        {
          dataField: "nil_tds_applicable",
          text: "Nil Tds Applicable",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cellContent, row) => {
            return (
              <span
                className={`${
                  selectedReport?.id === "common-nil-tds" && "text-success"
                }`}
              >
                {cellContent.toString()}
              </span>
            )
          },
        },
        {
          dataField: "user_demat_id",
          text: "Demat Id (as per System)",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cellContent, row) => {
            return <span>{cellContent}</span>
          },
        }
      )
    } else if (selectedReport?.id === "benpos-entries") {
      columns.push(
        {
          dataField: "debentures",
          text: "Lot Size (as per BenPos)",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cellContent, row) => {
            return <span className={`text-end d-block`}>{cellContent}</span>
          },
          // eslint-disable-next-line react/display-name
          headerFormatter: (column, colIndex) => (
            <div className="text-end">{column.text}</div>
          ),
        },
        {
          dataField: "bank_ifsc",
          text: "IFSC (as per BenPos)",
          sort: true,
        },
        {
          dataField: "bank_name",
          text: "Bank Name (as per BenPos)",
          sort: true,
        },
        {
          dataField: "bank_account_number",
          text: "Account Number (as per BenPos)",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cellContent, row) => {
            return <span className={`text-end d-block`}>{cellContent}</span>
          },
          // eslint-disable-next-line react/display-name
          headerFormatter: (column, colIndex) => (
            <div className="text-end">{column.text}</div>
          ),
        },
        {
          dataField: "nil_tds_applicable",
          text: "Nil Tds Applicable",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cellContent, row) => {
            return (
              <span
                className={`${
                  selectedReport?.id === "common-nil-tds" && "text-success"
                }`}
              >
                {cellContent.toString()}
              </span>
            )
          },
        },
        {
          dataField: "demat_id",
          text: "Demat Id (as per BenPos)",
          sort: true,
        }
      )
    } else if (selectedReport?.id == "common-nil-tds") {
      columns.push(
        {
          dataField: "nil_tds_applicable",
          text: "Nil Tds Applicable",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cellContent, row) => {
            return (
              <span
                className={`${
                  selectedReport?.id === "common-nil-tds" && "text-success"
                }`}
              >
                {cellContent.toString()}
              </span>
            )
          },
        },
        {
          dataField: "total_benpos_debenture",
          text: "Lot Size (as per BenPos)",
          sort: true, // eslint-disable-next-line react/display-name
          formatter: (cellContent, row) => {
            return (
              <span
                className={`${
                  selectedReport?.id === "common-debenture-mismatch"
                    ? row.total_benpos_debenture ===
                      row.total_investor_debenture
                      ? "text-success"
                      : "text-danger"
                    : ""
                } text-end d-block`}
              >
                {cellContent}
              </span>
            )
          },
          // eslint-disable-next-line react/display-name
          headerFormatter: (column, colIndex) => (
            <div className="text-end">{column.text}</div>
          ),
        },
        {
          dataField: "total_investor_debenture",
          text: "Lot Size (as per System)",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cellContent, row) => {
            return <span className={`text-end d-block`}>{cellContent}</span>
          },
          // eslint-disable-next-line react/display-name
          headerFormatter: (column, colIndex) => (
            <div className="text-end">{column.text}</div>
          ),
        },
        // {
        //   dataField: "bank_ifsc",
        //   text: "BenPos IFSC",
        //   sort: true,
        // },
        // {
        //   dataField: "user_ifsc_code",
        //   text: "Investor IFSC Code",
        //   sort: true,
        //   // eslint-disable-next-line react/display-name
        //   formatter: (cellContent, row) => {
        //     return <span className={`text-end d-block`}>{cellContent}</span>
        //   },
        // },
        // { dataField: "bank_name", text: "BenPos Bank Name", sort: true },
        // {
        //   dataField: "bank_account_number",
        //   text: "BenPos Account Number",
        //   sort: true,
        //   // eslint-disable-next-line react/display-name
        //   formatter: (cellContent, row) => {
        //     return <span className={`text-end d-block`}>{cellContent}</span>
        //   },
        // },
        // {
        //   dataField: "user_account_number",
        //   text: "Investor Account Number",
        //   sort: true,
        //   // eslint-disable-next-line react/display-name
        //   formatter: (cellContent, row) => {
        //     return <span className={`text-end d-block`}>{cellContent}</span>
        //   },
        // },

        {
          dataField: "demat_id",
          text: "Demat Id (as per BenPos)",
          sort: true, // eslint-disable-next-line react/display-name
          formatter: (cellContent, row) => {
            return <span>{cellContent}</span>
          },
        },
        {
          dataField: "user_demat_id",
          text: "Demat Id (as per System)",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cellContent, row) => {
            return <span>{cellContent}</span>
          },
        }
      )
    } else if (selectedReport?.id == "common-debenture-mismatch") {
      columns.push(
        {
          dataField: "total_benpos_debenture",
          text: "Lot Size (as per BenPos)",
          sort: true, // eslint-disable-next-line react/display-name
          formatter: (cellContent, row) => {
            return (
              <span
                className={`${
                  selectedReport?.id === "common-debenture-mismatch"
                    ? row.total_benpos_debenture ===
                      row.total_investor_debenture
                      ? "text-success"
                      : "text-danger"
                    : ""
                } text-end d-block`}
              >
                {cellContent}
              </span>
            )
          },
          // eslint-disable-next-line react/display-name
          headerFormatter: (column, colIndex) => (
            <div className="text-end">{column.text}</div>
          ),
        },
        {
          dataField: "total_investor_debenture",
          text: "Lot Size (as per System)",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cellContent, row) => {
            return <span className={`text-end d-block`}>{cellContent}</span>
          },
          // eslint-disable-next-line react/display-name
          headerFormatter: (column, colIndex) => (
            <div className="text-end">{column.text}</div>
          ),
        },
        {
          dataField: "nil_tds_applicable",
          text: "Nil Tds Applicable",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cellContent, row) => {
            return (
              <span
                className={`${
                  selectedReport?.id === "common-nil-tds" && "text-success"
                }`}
              >
                {cellContent.toString()}
              </span>
            )
          },
        },
        // {
        //   dataField: "bank_ifsc",
        //   text: "BenPos IFSC",
        //   sort: true,
        // },
        // {
        //   dataField: "user_ifsc_code",
        //   text: "Investor IFSC Code",
        //   sort: true,
        //   // eslint-disable-next-line react/display-name
        //   formatter: (cellContent, row) => {
        //     return <span className={`text-end d-block`}>{cellContent}</span>
        //   },
        // },
        // { dataField: "bank_name", text: "BenPos Bank Name", sort: true },
        // {
        //   dataField: "bank_account_number",
        //   text: "BenPos Account Number",
        //   sort: true,
        //   // eslint-disable-next-line react/display-name
        //   formatter: (cellContent, row) => {
        //     return <span className={`text-end d-block`}>{cellContent}</span>
        //   },
        // },
        // {
        //   dataField: "user_account_number",
        //   text: "Investor Account Number",
        //   sort: true,
        //   // eslint-disable-next-line react/display-name
        //   formatter: (cellContent, row) => {
        //     return <span className={`text-end d-block`}>{cellContent}</span>
        //   },
        // },

        {
          dataField: "demat_id",
          text: "Demat Id (as per BenPos)",
          sort: true, // eslint-disable-next-line react/display-name
          formatter: (cellContent, row) => {
            return <span>{cellContent}</span>
          },
        },
        {
          dataField: "user_demat_id",
          text: "Demat Id (as per System)",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cellContent, row) => {
            return <span>{cellContent}</span>
          },
        }
      )
    }
    return columns
  }

  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    // paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "60",
        value: 60,
      },
      {
        text: "100",
        value: 100,
      },
    ],
  }

  const filterUrl = `${investmentId ? `&investmentId=${investmentId}` : ""}${
    benposdate ? `&benpos_date=${benposdate}` : ""
  }`

  const GetReportCounts = async () => {
    const getUrl = `/benpos-report/exception-report-count?investmentId=${investmentId}&benpos_date=${benposdate}`
    try {
      const response = await axiosInstance.get(getUrl)
      if (response) {
        const data = response?.data?.data || response?.data
        setReportCountData(data)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
  }

  const masterData = async () => {
    if (!selectedReport) {
      return null
    }
    setLoading(true)
    const getUrl = `/benpos-report/${
      selectedReport?.filterUrl || selectedReport.endpoint
    }${filterUrl}`

    try {
      const response = await axiosInstance.get(getUrl)
      if (response) {
        const data = response?.data?.data || response?.data
        const sortingData = data.sort((a, b) => {
          const getPriority = item => {
            if (item.bank_account_matched && !item.ifsc_matched) {
              if (!item.bank_ifsc) return 1
              return 2
            }
            return 3
          }
          return getPriority(a) - getPriority(b)
        })
        const total = response?.data?.length || response?.length
        setTableData(sortingData)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
  }

  const getApprovalPolicy = async () => {
    try {
      const response = await axiosInstance.get(
        `/interest-approval?investmentId=${investmentId}&report_type=INTEREST_CALCULATION&status=APPROVED&benpos_date=${benposdate}`
      )
      const respData = response?.data?.data || response?.data

      if (respData && respData.length > 0) {
        setIsApproved(true)
      } else {
        setIsApproved(false)
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  const { SearchBar } = Search

  useEffect(async () => {
    if (selectedReport) {
      masterData()
    }
  }, [selectedReport])

  useEffect(() => {
    GetReportCounts()
    getApprovalPolicy()
  }, [])

  const downloadData = async () => {
    try {
      const data = tableData
      const csvTableHeaders = tableColumns(true)
      const str = JSON.stringify(data)
      const arr = JSON.parse(str)
      arr.forEach(el => {
        if (selectedReport.id === "common-benpos-bank-details") {
          el.bank_ifsc = el.manual_ifsc_code
            ? el.manual_ifsc_code
            : el.bank_account_number === el.user_account_number
            ? el.user_ifsc_code
            : el.bank_ifsc
          el.bank_account_number_exception =
            el.bank_account_number == el.user_account_number
              ? "Account Number Matched"
              : "Account Number Not Matched"
          el.bank_ifsc_exception = el.bank_ifsc
            ? el.ifsc_matched
              ? "IFSC Matched"
              : "IFSC Not Matched"
            : "IFSC Missing"
        }
        if (selectedReport?.id == "system-entries") {
          el.investor_name = el.name_on_pan
        }
        return el
      })
      const downloadableArr = csvDownloadData(csvTableHeaders, arr)
      JSONToCSVConvertor(downloadableArr, selectedReport?.name, true)
    } catch (error) {
      toast.error(error?.message)
    }
  }

  const uploadAttachmentHandler = async () => {
    try {
      const formData = new FormData()
      formData.append("file", proofAttachment)
      const response = await axiosInstance.post(
        `/file-upload/?type=exception_report`,
        formData
      )
      if (response) {
        return response?.data?.[0]?.name
      }
    } catch (error) {
      toast.error(error?.message)
      throw error
    }
  }
  const handleFileChange = e => {
    const file = e.target.files[0]
    if (file) {
      setProofAttachment(file)
    }
  }

  const handleValidIfscForm = async (e, values) => {
    if (!manualIfcs || !proofAttachment) {
      toast.error("All fields are required")
      return
    }
    setLoading(true)
    try {
      const proofFile = await uploadAttachmentHandler()
      // Account Number Matched and Missing IFSC (IFSC Auto-fill from KYC)
      let prev_ifsc = selectedData.bank_ifsc
      if (
        !prev_ifsc &&
        selectedData.bank_account_number == selectedData.user_account_number
      ) {
        prev_ifsc = selectedData.user_ifsc_code
      }
      if (
        selectedData.bank_account_number != selectedData.user_account_number
      ) {
      }
      const response = await axiosInstance.patch(
        `benpos/${selectedData?.benpos_id}`,
        {
          manual_ifsc_code: prev_ifsc == manualIfcs ? undefined : manualIfcs,
          manual_account_number:
            selectedData.bank_account_number == selectedData.user_account_number
              ? undefined
              : `${leadingZero.value || ""}${
                  selectedData?.bank_account_number
                }`,
          bank_update_proof_file: proofFile,
        }
      )
      if (response) {
        toast.success("Bank Details Updated successfully")
        masterData()
        toggleIFSCModal()
        setManualIfsc("")
        setProofAttachment()
        setLeadingZero(leadingZeroOptions[0])
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        {accessControlData?.permissions?.BENPOS_EXCEPTION_REPORT ? (
          <Container fluid>
            <HeaderWithBackBtn title={"Exception Report"} />
            <hr />
            <BenposProjectCard
              investmentId={investmentId}
              benpos_date={benposdate}
            />
            <Row>
              <Col xs="12">
                <Row className="mb-2 row justify-content-between align-items-center">
                  <div className="col-md-8">
                    <Row className="mb-4">
                      <div className="col-md-6">
                        <label className="card-title">Report Type</label>
                        <ReactSelect
                          users={[
                            {
                              label: (
                                <div className="d-flex align-items-center gap-1 font-size-13">
                                  <i className="mdi mdi-information-outline font-size-16 text-secondary"></i>
                                  Action Required
                                </div>
                              ),
                              options: reportCountData.slice(0, 1),
                            },
                            {
                              label: (
                                <div className="d-flex align-items-center gap-1 font-size-13">
                                  <svg
                                    viewBox="0 0 24 24"
                                    width="1rem"
                                    fill={"#777"}
                                  >
                                    <path d="M6,2A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6M6,4H13V9H18V20H6V4M8,12V14H16V12H8M8,16V18H13V16H8Z" />
                                  </svg>
                                  Other Reports
                                </div>
                              ),
                              options: reportCountData.slice(1),
                            },
                          ]}
                          multiOptionLabel={true}
                          optionLabelKeys={["label"]}
                          selectedOption={selectedReport}
                          setSelectedOption={item => {
                            setSelectedExceptionFilter(null)
                            setSelectedReport(item)
                          }}
                        />
                      </div>
                    </Row>
                  </div>
                  {selectedReport ? (
                    <div className="col-auto">
                      <AddRemarkBtn
                        benpos_date={benposdate}
                        investmentId={investmentId}
                        report_type={"EXCEPTION_REPORT"}
                      />
                      {accessControlData?.permissions?.BENPOS_DOWNLOAD ? (
                        <>
                          <Button
                            type="button"
                            color="primary"
                            className="btn-rounded mb-2 mx-2"
                            style={{ marginRight: "3px" }}
                            onClick={downloadData}
                          >
                            <i className="mdi mdi-arrow-down-bold-circle"></i>{" "}
                            {accessControlData?.permissions?.BENPOS_DOWNLOAD}
                          </Button>
                        </>
                      ) : null}
                    </div>
                  ) : null}
                </Row>
                {!selectedReport && !loading ? (
                  <Row>
                    <Col lg="12">
                      <div className="text-center">
                        <Row className="justify-content-center">
                          <Col sm="4">
                            <div className="maintenance-img">
                              <img
                                src={nodata}
                                alt=""
                                className="img-fluid mx-auto d-block"
                              />
                            </div>
                          </Col>
                        </Row>
                        <h4 className="mt-5">Please select a report</h4>
                      </div>
                    </Col>
                  </Row>
                ) : null}

                {selectedReport && !loading ? (
                  <Card>
                    <CardBody>
                      <ToolkitProvider
                        keyField="id"
                        data={tableData}
                        columns={tableColumns()}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2 row align-items-end">
                              {selectedReport.id ==
                              "common-benpos-bank-details" ? (
                                <Col md={3}>
                                  <div className="mb-3">
                                    <label>Exception Type</label>
                                    <ReactSelect
                                      users={ExceptionFilter}
                                      selectedOption={selectedExceptionFilter}
                                      setSelectedOption={filter => {
                                        setSelectedExceptionFilter(filter)
                                        setSelectedReport({
                                          ...selectedReport,
                                          filterUrl: filter?.endpoint || "",
                                        })
                                      }}
                                      multiOptionLabel={true}
                                      optionLabelKeys={["label"]}
                                      isClearable={true}
                                    />
                                  </div>
                                </Col>
                              ) : null}
                              <Col md={3}>
                                <div className="search-box me-2 mb-1 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <BootstrapTable
                                  keyField="id"
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  columns={tableColumns()}
                                  data={tableData}
                                  pagination={paginationFactory(pageOptions)}
                                  wrapperClasses={"table-responsive mb-4"}
                                  classes={
                                    "table align-middle table-nowrap table-check"
                                  }
                                  headerWrapperClasses={"table-light"}
                                  {...toolkitProps.baseProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    </CardBody>
                  </Card>
                ) : null}
              </Col>
            </Row>
            <Modal
              isOpen={editIfscModal}
              toggle={toggleIFSCModal}
              className="modal-lg overflow-auto"
              backdrop="static"
              keyboard={false}
              scrollable={true}
            >
              <div className="modal-header">
                <h4 className="modal-title mt-0">Update Bank Details</h4>
                <button
                  type="button"
                  onClick={toggleIFSCModal}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <ModalBody className="p-4 pb-0">
                <Row className="mb-4">
                  <Col className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <div>Project Name</div>
                        <p
                          className="fw-medium font-size-15"
                          style={{
                            fontSize: "13px",
                          }}
                        >
                          {selectedData?.project_name}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <div>BenPos Date</div>
                        <p
                          className="fw-medium font-size-15"
                          style={{
                            fontSize: "13px",
                          }}
                        >
                          {handleValidDate(selectedData?.benpos_date)}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <div>Investor Name</div>
                        <p
                          className="fw-medium font-size-15"
                          style={{
                            fontSize: "13px",
                          }}
                        >
                          {selectedData?.investor_name}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <div>Category</div>
                        <p
                          className="fw-medium font-size-15"
                          style={{
                            fontSize: "13px",
                          }}
                        >
                          {selectedData?.category}
                        </p>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-3">
                        <div>Account Number (as per System)</div>
                        <p
                          className="fw-medium font-size-15"
                          style={{
                            fontSize: "13px",
                          }}
                        >
                          {selectedData?.user_account_number || "NA"}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <div>Account Number (as per BenPos)</div>
                        <p
                          className="fw-medium font-size-15"
                          style={{
                            fontSize: "13px",
                          }}
                        >
                          {selectedData?.bank_account_number || "NA"}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <div>IFSC (as per System)</div>
                        <p
                          className="fw-medium font-size-15"
                          style={{
                            fontSize: "13px",
                          }}
                        >
                          {selectedData?.user_ifsc_code || "NA"}
                        </p>
                      </div>
                    </div>
                    <hr />
                    <AvForm>
                      <div className="row">
                        {/* KRISH: Removed IFSC Manual Input when A/c No. not Matched and IFSC Mismatch */}

                        {!selectedData?.bank_account_matched ? (
                          !selectedData?.bank_ifsc ? (
                            <>
                              <div className="mb-3 col-md-6">
                                <AvField
                                  name="manual_ifcs_code"
                                  label="IFSC (as per BenPos)"
                                  type="text"
                                  errorMessage="Invalid value"
                                  sort="true"
                                  validate={{
                                    required: { value: true },
                                  }}
                                  value={manualIfcs}
                                  onChange={e => setManualIfsc(e.target.value)}
                                />
                              </div>
                              <div className="mb-3 col-md-6">
                                <div className="d-flex justify-content-between align-items-center">
                                  <label htmlFor="leadingzero">
                                    No. of Leading Zeros
                                  </label>
                                  <label htmlFor="leadingzero">Preview</label>
                                </div>
                                <div className="d-flex justify-content-between align-items-center">
                                  <div className="col-md-4 position-relative">
                                    <ReactSelect
                                      users={leadingZeroOptions}
                                      optionLabelKeys={["label"]}
                                      selectedOption={leadingZero}
                                      setSelectedOption={item =>
                                        setLeadingZero(item)
                                      }
                                    />
                                  </div>
                                  <p className="mb-0">
                                    {leadingZero?.value || ""}
                                    {selectedData?.bank_account_number || ""}
                                  </p>
                                </div>
                              </div>
                            </>
                          ) : (
                            <div className="mb-3 col-md-6">
                              <div className="d-flex justify-content-between align-items-center">
                                <label htmlFor="leadingzero">
                                  No. of Leading Zeros
                                </label>
                                <label htmlFor="leadingzero">Preview</label>
                              </div>
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="col-md-4 position-relative">
                                  <ReactSelect
                                    users={leadingZeroOptions}
                                    optionLabelKeys={["label"]}
                                    selectedOption={leadingZero}
                                    setSelectedOption={item =>
                                      setLeadingZero(item)
                                    }
                                  />
                                </div>
                                <p className="mb-0">
                                  {leadingZero?.value || ""}
                                  {selectedData?.bank_account_number || ""}
                                </p>
                              </div>
                            </div>
                          )
                        ) : (
                          <div className="mb-3 col-md-6">
                            <AvField
                              name="manual_ifcs_code"
                              label="IFSC (as per BenPos)"
                              type="text"
                              errorMessage="Invalid value"
                              sort="true"
                              validate={{
                                required: { value: true },
                              }}
                              value={manualIfcs}
                              onChange={e => setManualIfsc(e.target.value)}
                            />
                          </div>
                        )}
                        <div className="mb-3 col-md-6">
                          <AvField
                            className="form-control"
                            type="file"
                            id="formFile"
                            validate={{
                              required: { value: false },
                            }}
                            name="Attachments"
                            label="Upload Proof"
                            onChange={handleFileChange}
                            accept="image/*,.pdf"
                          />
                        </div>
                      </div>
                    </AvForm>
                    {selectedData?.bank_update_proof_file && (
                      <a
                        target="_blank"
                        rel="noreferrer"
                        className="block text-end mb-2"
                        onClick={() => {
                          DocumentOpenHandler(
                            selectedData?.bank_update_proof_file
                          )
                        }}
                      >
                        View Uploaded Proof
                      </a>
                    )}
                  </Col>
                </Row>
                <Row>
                  <div className="modal-footer mt-5">
                    <button
                      type="button"
                      className="btn btn-success"
                      onClick={handleValidIfscForm}
                      disabled={!proofAttachment || !manualIfcs}
                    >
                      Submit
                    </button>
                  </div>
                </Row>
              </ModalBody>
            </Modal>
            <AuditTrail
              recordId={selectedLogId}
              modal={auditTrailModal}
              setModal={setAuditTrailModal}
            />
          </Container>
        ) : (
          <Unauthorized />
        )}
      </div>
    </React.Fragment>
  )
}

export default ExceptionReport
