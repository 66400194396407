import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"
import BootstrapTable from "react-bootstrap-table-next"
import { handleValidDate, investmentData } from "constants/common"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import {
  Button,
  Card,
  Col,
  Container,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip,
  TabPane,
  TabContent,
  NavItem,
  NavLink,
  CardBody,
} from "reactstrap"
import Loader from "common/Loader"
import { permissionChecker } from "common/PermissionChecker"
import Unauthorized from "common/Unauthorized"
import HeaderWithBackBtn from "components/Common/HeaderWithBackBtn"
import { useLocation, useParams } from "react-router-dom"
import paginationFactory from "react-bootstrap-table2-paginator"
import { AvForm, AvField } from "availity-reactstrap-validation"
import ReactSelect from "constants/ReactSelect"
import AddRemarkBtn from "components/Remark/AddRemarkBtn"
import BenposProjectCard from "components/Common/BenposProjectCard"
import TabTableComponent from "./TabTableComponent"
import classnames from "classnames"
import { JSONToCSVConvertor } from "common/jsontocsv"
import getBase64 from "base64"
import nodata from "../../assets/images/nodata/no-data.svg"

const BankTransfer = () => {
  const [bankMismatchData, setBankMismatchData] = useState([])
  const [rowMismatchData, setRowMismatchData] = useState([])
  const [tableData, setTableData] = useState([])
  const [panNameMismatchData, setPanNameMismatchData] = useState([])
  const [loading, setLoading] = useState(false)
  const [bankAccountList, setBankAccountList] = useState([])
  const [generateData, setGenerateData] = useState({
    bankAccount: null,
    date: null,
    disabled: false,
  })

  const [downloading, setDownloading] = useState(false)

  const [accessControlData, setAccessControlData] = useState([])
  const [selectedTab, setSelectedTab] = useState(1)

  const [approveModal, setApproveModal] = useState(false)
  const [compareModal, setCompareModal] = useState(false)
  const [selectedAction, setSelectedAction] = useState("")
  const [actionReason, setActionReason] = useState("")
  const [consent, setConsent] = useState(false)
  const [myPolicy, setMyPolicy] = useState(null)
  const localUser = JSON.parse(localStorage.getItem("userInfo"))
  const [compareActiveTab, setCompareActiveTab] = useState(1)
  const [activeTab, setActiveTab] = useState(1)
  const [file, setFile] = useState(null)
  const [base64, setBase64] = useState("")
  const [benposDiff, setBenposDiff] = useState([])
  const [selectedProject, setSelectedProject] = useState(null)
  const [fileName, setFileName] = useState("")
  const [compareBenpos, setCompareBenpos] = useState(null)
  const [sourceDetails, setSourceDetails] = useState("")
  const [receivedOnDate, setReceivedOnDate] = useState()

  useEffect(() => {
    const permissions = permissionChecker("/benpos-master")
    setAccessControlData(permissions)
  }, [])

  const params = useParams()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const startDate = queryParams.get("start_date")
  const endDate = queryParams.get("end_date")

  const { benposdate, investmentId } = params

  const dollarIndianLocale = Intl.NumberFormat("en-IN")
  const handleAmount = amount => {
    return dollarIndianLocale.format(amount)
  }

  // Table Columns
  const TableColumns = () => {
    const columns = [
      {
        dataField: "s_no",
        text: "S No.",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cell, row, rowIndex) => rowIndex + 1,
      },
      { dataField: "investor_name", text: "Client Name", sort: true },
      {
        dataField: "benpos_account_no",
        text: "Benpos Account No.",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) =>
          row.manual_account_number
            ? row.manual_account_number
            : row.bank_account_number,
      },
      {
        dataField: "bank_ifsc",
        text: "IFSC Code",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) =>
          row.manual_ifsc_code
            ? row.manual_ifsc_code
            : row.bank_ifsc
            ? row.bank_ifsc
            : row.bank_account_number === row.user_account_number
            ? row.user_ifsc
            : "-",
      },
      {
        dataField: "net_interest",
        text: "Amount",
        sort: true, // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => handleAmount(cellContent),
      },
      {
        dataField: "",
        text: "Investment Bank Name",
        sort: true, // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) =>
          generateData?.bankAccount?.client_name || "NA",
      },
      {
        dataField: "",
        text: "Investment Bank Account No.",
        sort: true, // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) =>
          generateData?.bankAccount?.account_no || "NA",
      },
      {
        dataField: "",
        text: "Investment Bank IFSC Code",
        sort: true, // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) =>
          generateData?.bankAccount?.ifsc_code || "NA",
      },
      {
        dataField: "",
        text: "Narration",
        sort: true, // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) =>
          generateData?.bankAccount?.narration || "NA",
      },
    ]
    return columns
  }

  const BankMismatchColumns = () => {
    let columns = [
      {
        dataField: "investor_name",
        text: "Investor Name",
        sort: true,
      },
      {
        dataField: "bank_ifsc",
        text: "IFSC (as per BenPos)",
        sort: true,
      },
      {
        dataField: "bank_ifsc",
        text: "IFSC (as per Bank File)",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (benpos_ifsc, row) => {
          if (row.manual_ifsc_code) {
            return (
              <span className={` d-flex align-items-center text-primary`}>
                {row.manual_ifsc_code}
                <i
                  className="mdi mdi-information-outline font-size-16 ms-2 text-secondary"
                  id={`ifsctooltip-${row.id}`}
                  style={{ cursor: "pointer" }}
                />
                <UncontrolledTooltip target={`#ifsctooltip-${row.id}`}>
                  Replaced with Manually added IFSC Code
                </UncontrolledTooltip>
              </span>
            )
          }

          if (benpos_ifsc) {
            return benpos_ifsc
          }

          if (row.bank_account_number === row.user_account_number) {
            return (
              <span className="text-primary">
                {row.user_ifsc_code}
                <i
                  className="mdi mdi-information-outline font-size-16 ms-2 text-secondary"
                  id={`ifsctooltip-${row.id}`}
                  style={{ cursor: "pointer" }}
                />
                <UncontrolledTooltip target={`#ifsctooltip-${row.id}`}>
                  Replaced with IFSC (as per System) as Account Number Matched
                  in both BenPos & System
                </UncontrolledTooltip>
              </span>
            )
          }
          return "-"
        },
      },
      {
        dataField: "bank_account_number",
        text: "Account Number (as per BenPos)",
        sort: true,
      },
      {
        dataField: "bank_account_number",
        text: "Account Number (as per Bank File)",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => {
          if (row.manual_account_number) {
            return (
              <span className={`d-flex align-items-center text-primary`}>
                {row.manual_account_number}
                <i
                  className="mdi mdi-information-outline font-size-16 ms-2 text-secondary"
                  id={`bankAccounttooltip-${row.id}`}
                  style={{ cursor: "pointer" }}
                />
                <UncontrolledTooltip target={`#bankAccounttooltip-${row.id}`}>
                  Replaced with Manually added Account Number
                </UncontrolledTooltip>
              </span>
            )
          }
          return <span className={`d-block`}>{cellContent}</span>
        },
      },
      {
        dataField: "bank_name",
        text: "Bank Name (as per BenPos)",
        sort: true,
      },
    ]
    return columns
  }

  const PanMismatchColumns = () => {
    const columns = [
      {
        dataField: "name_on_pan",
        text: "Investor Name (as per System)",
        sort: true,
      },
      {
        dataField: "investor_name",
        text: "Investor Name (as per BenPos)",
        sort: true,
      },
      {
        dataField: "pan_number",
        text: "PAN Number",
        sort: true,
      },
      {
        dataField: "demat_id",
        text: "Demat Id",
        sort: true,
      },
      {
        dataField: "user_account_number",
        text: "Account Number",
        sort: true,
      },
      {
        dataField: "user_ifsc_code",
        text: "IFSC",
        sort: true,
      },
    ]
    return columns
  }

  // Main Function - Fetch Table Data
  const getAllData = async () => {
    try {
      setLoading(true)
      await getBankFileData()
      await getRowMismatchData()
      await getBankDetailsMismatchData()
      await getPanNameMismatchData()
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  const getBankFileData = async () => {
    setTableData([])
    if (!startDate && !endDate) {
      toast.error("Invalid Url")
      return
    }
    const getUrl = `/benpos-interest-calculation-report/investor-wise?investmentId=${investmentId}&benpos_date=${benposdate}&startDate=${startDate}&endDate=${endDate}`

    const response = await axiosInstance.get(getUrl)

    if (response) {
      const data = response?.data?.data || response?.data
      const filteredData = data.filter(item => !item.isSubtotal)
      setTableData(filteredData)
    }
  }

  const getRowMismatchData = async () => {
    setRowMismatchData([])
    if (!startDate && !endDate) {
      toast.error("Invalid Url")
      return
    }
    const getUrl = `/compare-benpos/bank-transfer?investmentId=${investmentId}&benpos_date=${benposdate}&startDate=${startDate}&endDate=${endDate}&source=benpos`

    const response = await axiosInstance.get(getUrl)

    if (response) {
      const data = response?.data?.data || response?.data
      const filteredData = data.filter(item => !item.isSubtotal)
      setRowMismatchData(filteredData)
    }
  }

  const getBankDetailsMismatchData = async () => {
    setBankMismatchData([])
    const getUrl = `benpos-report/exception?category=COMMON&category=BENPOS&bank_details_exception=true&investmentId=${investmentId}&benpos_date=${benposdate}&startDate=${startDate}&endDate=${endDate}`

    const response = await axiosInstance.get(getUrl)

    if (response) {
      const data = response?.data?.data || response?.data
      setBankMismatchData(data)
    }
  }

  const getPanNameMismatchData = async () => {
    setPanNameMismatchData([])
    const getUrl = `benpos-report/exception?category=COMMON&category=BENPOS&name_mismatch=true&investmentId=${investmentId}&benpos_date=${benposdate}&startDate=${startDate}&endDate=${endDate}`

    const response = await axiosInstance.get(getUrl)

    if (response) {
      const data = response?.data?.data || response?.data
      setPanNameMismatchData(data)
    }
  }

  const GenerateBankFileHandler = async () => {
    try {
      setDownloading(true)

      const downloadUrl = `/download-excel?type=bank-transfer-file&date=${generateData.date}&bank_account_id=${generateData.bankAccount?.id}&investmentId=${investmentId}&benpos_date=${benposdate}&start_date=${startDate}&end_date=${endDate}`
      const response = await axiosInstance.get(downloadUrl, {
        responseType: "blob",
      })

      const contentDispositionHeader = response.headers["content-disposition"]
      let match
      if (contentDispositionHeader) {
        match = contentDispositionHeader.split("filename=")
      }

      const filename = match ? match[1] : "download.xlsx"
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
      const downloadLink = document.createElement("a")
      downloadLink.href = URL.createObjectURL(blob)
      downloadLink.download = filename
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
    } catch (error) {
      toast.error(error?.message)
    } finally {
      setDownloading(false)
    }
  }

  const tabOptions = [
    { id: 1, label: "Bank Transfer File" },
    { id: 2, label: "Not Found Entries" },
    { id: 3, label: "Name Mismatch" },
    { id: 4, label: "Bank Details Mismatch" },
  ]

  useEffect(async () => {
    if (file) {
      setLoading(true)
      new Promise((resolve, reject) => {
        getBase64(file, data => resolve(data))
      }).then(result => {
        setBase64(result)
        setLoading(false)
      })
    }
  }, [file])

  const handleCompareModal = () => {
    setCompareActiveTab(1)
    toggleCompareModal()
  }

  const handleApprove = () => {
    setSelectedAction("APPROVE")
    setActiveTab(1)
    setActionReason("")
    setConsent(false)
    toggleApproveModal()
  }
  const handleReject = () => {
    setSelectedAction("REJECT")
    setActionReason("")
    setConsent(false)
    toggleApproveModal()
  }
  const toggleApproveModal = () => {
    setApproveModal(!approveModal)
  }

  const toggleCompareModal = async () => {
    setSourceDetails("")
    setReceivedOnDate()
    setCompareModal(!compareModal)
    if (compareModal === true) {
      await masterData()
    }
  }

  const handleReportAction = async (e, values) => {
    try {
      setLoading(true)
      const payload = {
        modifiedById: localUser.id,
        investmentId: investmentId,
        benpos_date: benposdate,
        status: selectedAction == "APPROVE" ? "APPROVED" : "REJECTED",
        report_type: "BANK_TRANSFER_FILE",
        comment: actionReason,
        start_date: startDate,
        end_date: endDate,
        data: {
          transfer_date: generateData.date,
          investmentBankAccountId: generateData.bankAccount?.id,
          file_name: selectedAction == "APPROVE" ? fileName : undefined,
        },
      }

      const res = await axiosInstance.post(`/interest-approval`, payload)
      if (res) {
        await masterData()
        toast.success(
          `${
            selectedAction == "APPROVE" ? "Approved" : "Rejected"
          } Successfully`
        )
        toggleApproveModal()
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }
  const defaultSorted = [
    {
      dataField: "created_at",
      order: "desc",
    },
  ]

  const masterData = async () => {
    try {
      setLoading(true)
      await getUploadedBenposUserData()
      const res = await axiosInstance.get(
        `/investment-bank-account?$sort[created_at]=-1&investmentId=${investmentId}`
      )
      if (res) {
        setBankAccountList(res?.data?.data || res?.data)

        const response = await axiosInstance.get(
          `/interest-approval-policy?investmentId=${investmentId}&benpos_date=${benposdate}&report_type=BANK_TRANSFER_FILE`
        )
        if (response) {
          const respData = response?.data?.data || response?.data
          setMyPolicy(respData.find(item => item.userId == localUser.id))
          const isTransferDateExists = respData.find(
            obj => obj.interest_approval
          )?.interest_approval

          if (
            isTransferDateExists &&
            isTransferDateExists?.data &&
            isTransferDateExists?.data?.transfer_date
          ) {
            setGenerateData({
              date: isTransferDateExists?.data?.transfer_date,
              bankAccount: res?.data?.data?.find(
                obj =>
                  obj.id == isTransferDateExists.data.investmentBankAccountId
              ),
              disabled: true,
            })
          }
        }
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  const getUploadedBenposUserData = async () => {
    const localUser = JSON.parse(localStorage.getItem("userInfo"))
    try {
      const res = await axiosInstance.get(
        `/user-benpos?investmentId=${investmentId}&userId=${localUser?.id}&benpos_date=${benposdate}&$limit=1`
      )
      if (res) {
        const data = res?.data?.data || res?.data
        if (data && data.length === 1) {
          setCompareBenpos(data[0].is_matched)
        } else {
          setCompareBenpos(null)
        }
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  useEffect(() => {
    masterData()
  }, [])

  const handleCsvDownload = (json, projectName) => {
    JSONToCSVConvertor(json, projectName, true)
  }

  const sampleCsv = [
    {
      investor_name: "Test User",
      pan_number: "ABCDE0123F",
      demat_id: "1234567890123456",
      debentures: 10,
      bank_name: "HDFC",
      bank_account_number: "12345678901",
      bank_ifsc: "ABCD0012345",
    },
  ]

  const onSelectFile = e => {
    setFileName(e.target.files[0].name)
    setFile(e.target.files[0])
  }

  const uploadBenpos = async () => {
    try {
      if (!base64) {
        toast.error("Please select valid file")
        return
      }
      setLoading(true)
      const dataToUpload = {
        benpos: base64.replace("data:text/csv;base64,", ""),
        investmentId: investmentId,
        benpos_date: benposdate,
        source_details: sourceDetails,
        received_on: receivedOnDate,
      }
      const uploadResponse = await axiosInstance.post(
        "/upload-base-64?type=user_benpos",
        dataToUpload
      )
      if (uploadResponse) {
        setCompareActiveTab(2)
        setBenposDiff(uploadResponse.data.data)
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  const BenposDiffColumn = [
    {
      text: "Investor Name (System Benpos)",
      dataField: "system_benpos_investor_name",
      // eslint-disable-next-line react/display-name
      formatter: (cell, row) => {
        return (
          <span
            className={
              row.system_benpos_investor_name == row.user_benpos_investor_name
                ? ``
                : "text-danger"
            }
          >
            {cell || "-"}
          </span>
        )
      },
    },
    {
      text: "Investor Name (Your Benpos)",
      dataField: "user_benpos_investor_name",
      // eslint-disable-next-line react/display-name
      formatter: (cell, row) => {
        return (
          <span
            className={
              row.system_benpos_investor_name == row.user_benpos_investor_name
                ? ``
                : "text-danger"
            }
          >
            {cell || "-"}
          </span>
        )
      },
    },
    {
      text: "PAN Number (System Benpos)",
      dataField: "system_benpos_pan_number",
      // eslint-disable-next-line react/display-name
      formatter: (cell, row) => {
        return (
          <span
            className={
              row.system_benpos_pan_number == row.user_benpos_pan_number
                ? ``
                : "text-danger"
            }
          >
            {cell || "-"}
          </span>
        )
      },
    },
    {
      text: "PAN Number (Your Benpos)",
      dataField: "user_benpos_pan_number",
      // eslint-disable-next-line react/display-name
      formatter: (cell, row) => {
        return (
          <span
            className={
              row.system_benpos_pan_number == row.user_benpos_pan_number
                ? ``
                : "text-danger"
            }
          >
            {cell || "-"}
          </span>
        )
      },
    },
    {
      text: "Demat Id (System Benpos)",
      dataField: "system_benpos_demat_id",
      // eslint-disable-next-line react/display-name
      formatter: (cell, row) => {
        return (
          <span
            className={
              row.system_benpos_demat_id == row.user_benpos_demat_id
                ? ``
                : "text-danger"
            }
          >
            {cell || "-"}
          </span>
        )
      },
    },
    {
      text: "Demat Id (Your Benpos)",
      dataField: "user_benpos_demat_id",
      // eslint-disable-next-line react/display-name
      formatter: (cell, row) => {
        return (
          <span
            className={
              row.system_benpos_demat_id == row.user_benpos_demat_id
                ? ``
                : "text-danger"
            }
          >
            {cell || "-"}
          </span>
        )
      },
    },
    {
      text: "Debentures (System Benpos)",
      dataField: "system_benpos_debentures",
      // eslint-disable-next-line react/display-name
      formatter: (cell, row) => {
        return (
          <span
            className={
              row.system_benpos_debentures == row.user_benpos_debentures
                ? ``
                : "text-danger"
            }
          >
            {cell || "-"}
          </span>
        )
      },
    },
    {
      text: "Debentures (Your Benpos)",
      dataField: "user_benpos_debentures",
      // eslint-disable-next-line react/display-name
      formatter: (cell, row) => {
        return (
          <span
            className={
              row.system_benpos_debentures == row.user_benpos_debentures
                ? ``
                : "text-danger"
            }
          >
            {cell || "-"}
          </span>
        )
      },
    },
    {
      text: "Bank Name (System Benpos)",
      dataField: "system_benpos_bank_name",
      // eslint-disable-next-line react/display-name
      formatter: (cell, row) => {
        return (
          <span
            className={
              row.system_benpos_bank_name == row.user_benpos_bank_name
                ? ``
                : "text-danger"
            }
          >
            {cell || "-"}
          </span>
        )
      },
    },
    {
      text: "Bank Name (Your Benpos)",
      dataField: "user_benpos_bank_name",
      // eslint-disable-next-line react/display-name
      formatter: (cell, row) => {
        return (
          <span
            className={
              row.system_benpos_bank_name == row.user_benpos_bank_name
                ? ``
                : "text-danger"
            }
          >
            {cell || "-"}
          </span>
        )
      },
    },
    {
      text: "Bank Account Number (System Benpos)",
      dataField: "system_benpos_bank_account_number",
      // eslint-disable-next-line react/display-name
      formatter: (cell, row) => {
        return (
          <span
            className={
              row.system_benpos_bank_account_number ==
              row.user_benpos_bank_account_number
                ? ``
                : "text-danger"
            }
          >
            {cell || "-"}
          </span>
        )
      },
    },
    {
      text: "Bank Account Number (Your Benpos)",
      dataField: "user_benpos_bank_account_number",
      // eslint-disable-next-line react/display-name
      formatter: (cell, row) => {
        return (
          <span
            className={
              row.system_benpos_bank_account_number ==
              row.user_benpos_bank_account_number
                ? ``
                : "text-danger"
            }
          >
            {cell || "-"}
          </span>
        )
      },
    },
    {
      text: "IFSC Code (System Benpos)",
      dataField: "system_benpos_bank_ifsc",
      // eslint-disable-next-line react/display-name
      formatter: (cell, row) => {
        return (
          <span
            className={
              row.system_benpos_bank_ifsc == row.user_benpos_bank_ifsc
                ? ``
                : "text-danger"
            }
          >
            {cell || "-"}
          </span>
        )
      },
    },
    {
      text: "IFSC Code (Your Benpos)",
      dataField: "user_benpos_bank_ifsc",
      // eslint-disable-next-line react/display-name
      formatter: (cell, row) => {
        return (
          <span
            className={
              row.system_benpos_bank_ifsc == row.user_benpos_bank_ifsc
                ? ``
                : "text-danger"
            }
          >
            {cell || "-"}
          </span>
        )
      },
    },
  ]

  useEffect(() => {
    if (generateData.date && generateData.bankAccount) {
      getAllData()
    }
  }, [generateData.date, generateData.bankAccount])

  const onChangeBankAccount = e => {
    if (e.ifsc_code.slice(0, 4) === "HDFC") {
      setGenerateData({
        ...generateData,
        bankAccount: e,
      })
    } else {
      setGenerateData({
        ...generateData,
        bankAccount: null,
      })
      toast.error("Select HDFC Bank Account")
    }
  }

  return (
    <React.Fragment>
      {loading || downloading ? <Loader /> : null}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        {accessControlData?.permissions?.BENPOS_BANK_TRANSFER ? (
          <Container fluid>
            <HeaderWithBackBtn title={"Bank Transfer File"} />
            <hr />
            <Row>
              <Col xs="12">
                <Row className="mb-2 d-flex justify-content-between">
                  <BenposProjectCard
                    investmentId={investmentId}
                    benpos_date={benposdate}
                    startDate={startDate}
                    endDate={endDate}
                    onLoadComplete={setSelectedProject}
                  />
                  <Col className="d-flex w-full justify-content-end">
                    <Button
                      type="button"
                      color="primary"
                      className="btn-rounded mb-2 me-2"
                      onClick={handleCompareModal}
                      disabled={
                        myPolicy?.interest_approval?.status === "APPROVED"
                      }
                    >
                      <i className="mdi mdi-file-compare"></i> Compare your
                      Benpos
                    </Button>

                    {myPolicy?.id ? (
                      <>
                        {myPolicy?.interest_approval?.status === "APPROVED" ? (
                          <div className="inline-block mb-2 me-2 border px-3 border-success rounded-pill d-flex justify-content-center align-items-center text-success">
                            Approved By You
                          </div>
                        ) : (
                          <Button
                            type="button"
                            color="success"
                            className="btn-rounded mb-2 me-2"
                            onClick={handleApprove}
                            disabled={
                              compareBenpos !== true ||
                              tableData.length == 0 ||
                              loading
                            }
                          >
                            <i className="mdi mdi-check-circle"></i> Approve
                          </Button>
                        )}
                        {myPolicy?.interest_approval?.status === "REJECTED" ? (
                          <div className="inline-block mb-2 me-2 border px-3 border-danger rounded-pill d-flex justify-content-center align-items-center text-danger">
                            Rejected By You
                          </div>
                        ) : (
                          <Button
                            type="button"
                            color="danger"
                            className="btn-rounded mb-2 me-2"
                            onClick={handleReject}
                            disabled={tableData.length == 0 || loading}
                          >
                            <i className="mdi mdi-close-circle"></i> Reject
                          </Button>
                        )}
                      </>
                    ) : null}

                    <AddRemarkBtn
                      benpos_date={benposdate}
                      investmentId={investmentId}
                      report_type={"BANK_TRANSFER_FILE"}
                      disabled={tableData.length == 0 || loading}
                    />
                    <Button
                      type="button"
                      color="primary"
                      className="btn-rounded mb-2 ms-2"
                      onClick={GenerateBankFileHandler}
                      disabled={
                        tableData.length == 0 || loading || !compareBenpos
                      }
                    >
                      <i className="mdi mdi-arrow-down-bold-circle me-1"></i>
                      Download Bank File
                    </Button>
                  </Col>
                </Row>
                <Card className="mt-3">
                  <Row className="p-4 align-items-end">
                    <Col md={3}>
                      <div>
                        <label>Select Bank Account</label>
                        <ReactSelect
                          name="investmentId"
                          users={bankAccountList}
                          setSelectedOption={onChangeBankAccount}
                          selectedOption={generateData.bankAccount}
                          multiOptionLpabel={true}
                          optionLabelKeys={[
                            "client_name",
                            "account_no",
                            "ifsc_code",
                          ]}
                          isDisabled={generateData.disabled}
                        />
                      </div>
                    </Col>{" "}
                    <Col md={3}>
                      <div>
                        <Label>Transfer Date</Label>
                        <Input
                          name="date"
                          type="date"
                          value={generateData?.date}
                          onChange={e => {
                            setGenerateData({
                              ...generateData,
                              date: e.target.value,
                            })
                          }}
                          disabled={
                            !generateData.bankAccount || generateData.disabled
                          }
                          className="custom-input-height"
                        />
                      </div>
                    </Col>
                  </Row>
                  {generateData.date && generateData.bankAccount && !loading ? (
                    <>
                      <Col className="d-flex mt-3 gap-4 mx-3 w-full border-bottom border-2">
                        {tabOptions.map((item, index) => (
                          <button
                            onClick={() => setSelectedTab(index + 1)}
                            key={item.id}
                            className={`px-4 d-flex font-size-14 justify-content-center border-bottom border-2 bg-white py-2 border-0 align-items-center ${
                              selectedTab === index + 1
                                ? "border-primary bg-primary text-primary"
                                : "border-light text-dark"
                            }`}
                            style={{
                              marginBottom: "-2px",
                            }}
                          >
                            {item.label}
                            <span
                              className={`${
                                selectedTab === index + 1
                                  ? "bg-primary text-white"
                                  : "bg-light text-secondary"
                              } ms-2 font-size-12 text-center rounded-circle d-flex justify-content-center align-items-center`}
                              style={{
                                width: "22px",
                                height: "22px",
                              }}
                            >
                              {index + 1 === 1 && (tableData?.length || 0)}
                              {index + 1 === 2 &&
                                (rowMismatchData?.length || 0)}
                              {index + 1 === 3 &&
                                (panNameMismatchData?.length || 0)}
                              {index + 1 === 4 &&
                                (bankMismatchData?.length || 0)}
                            </span>
                          </button>
                        ))}
                      </Col>

                      <TabTableComponent
                        data={
                          (selectedTab === 1 && tableData) ||
                          (selectedTab === 2 && rowMismatchData) ||
                          (selectedTab === 3 && panNameMismatchData) ||
                          (selectedTab === 4 && bankMismatchData)
                        }
                        columns={
                          (selectedTab === 1 && TableColumns()) ||
                          (selectedTab === 2 && TableColumns()) ||
                          (selectedTab === 3 && PanMismatchColumns()) ||
                          (selectedTab === 4 && BankMismatchColumns())
                        }
                        noData={
                          (selectedTab == 2 && rowMismatchData.length == 0 ? (
                            <div className="p-3  font-size-15 d-flex gap-2 align-items-center text-success">
                              <i className="mdi mdi-check-circle font-size-20" />
                              Bank Transfer File and System BenPos is same
                            </div>
                          ) : null) ||
                          (selectedTab == 3 &&
                          panNameMismatchData.length == 0 ? (
                            <div className="p-3  font-size-15 d-flex gap-2 align-items-center text-success">
                              <i className="mdi mdi-check-circle font-size-20" />
                              No mismatched entries in System and BenPos
                            </div>
                          ) : null) ||
                          (selectedTab == 4 && bankMismatchData.length == 0 ? (
                            <div className="p-3  font-size-15 d-flex gap-2 align-items-center text-success">
                              <i className="mdi mdi-check-circle font-size-20" />
                              No mismatched entries in Bank Transfer File and
                              BenPos
                            </div>
                          ) : null)
                        }
                      />
                    </>
                  ) : (
                    <>
                      <Row className="pb-4">
                        <Col lg="12">
                          <div className="text-center">
                            <Row className="justify-content-center">
                              <Col sm="4">
                                <div className="maintenance-img">
                                  <img
                                    src={nodata}
                                    alt=""
                                    className="img-fluid mx-auto d-block"
                                  />
                                </div>
                              </Col>
                            </Row>
                            <h4 className="mt-5">
                              Select HDFC Bank Account and Transfer Date
                            </h4>
                          </div>
                        </Col>
                      </Row>
                    </>
                  )}
                </Card>
              </Col>
            </Row>
            {/* Compare Modal */}
            <Modal
              isOpen={compareModal}
              toggle={toggleCompareModal}
              size={"xl"}
            >
              <ModalHeader toggle={toggleCompareModal} tag="h4">
                Compare your Benpos File
              </ModalHeader>
              <ModalBody>
                <div className="vertical-wizard wizard clearfix vertical">
                  <div className="steps clearfix px-3">
                    <ul>
                      <NavItem
                        className={classnames({
                          current: compareActiveTab === 1,
                        })}
                      >
                        <NavLink
                          className={classnames({
                            current: compareActiveTab === 1,
                          })}
                          // onClick={handleBack}
                          disabled={true}
                        >
                          <div className="d-flex gap-2 align-items-center">
                            <span
                              className="number"
                              style={{ aspectRatio: "1/1" }}
                            >
                              1.
                            </span>{" "}
                            Upload Your BenPos
                          </div>
                        </NavLink>
                      </NavItem>
                      <NavItem
                        className={classnames({
                          current: compareActiveTab === 2,
                        })}
                      >
                        <NavLink
                          className={classnames({
                            active: compareActiveTab === 2,
                          })}
                          disabled={true}
                        >
                          <div className="d-flex gap-2 align-items-center">
                            <span
                              className="number"
                              style={{ aspectRatio: "1/1" }}
                            >
                              2.
                            </span>{" "}
                            Compare BenPos
                          </div>
                        </NavLink>
                      </NavItem>
                    </ul>
                  </div>
                  <div className="content clearfix">
                    <TabContent activeTab={compareActiveTab} className="body">
                      <TabPane tabId={1}>
                        <Row>
                          <Col md={3}>
                            <div className="mb-3">
                              <h4>{selectedProject?.project_name || "NA"}</h4>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <AvForm>
                            <Col className="col-12">
                              <Label className="form-label">
                                Upload Your BenPos (
                                <span
                                  className="text-primary"
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    handleCsvDownload(
                                      sampleCsv,
                                      "Sample BenPos",
                                      true
                                    )
                                  }
                                >
                                  Sample CSV
                                </span>
                                )
                              </Label>
                              {activeTab == 1 ? (
                                <Input
                                  className="form-control"
                                  type="file"
                                  accept=".csv"
                                  id="formFile"
                                  validate={{
                                    required: { value: true },
                                  }}
                                  onChange={onSelectFile}
                                />
                              ) : null}
                            </Col>
                            <Col className="col-12 mt-3">
                              <AvField
                                name="source_details"
                                label="Source Details"
                                type="textarea"
                                errorMessage="Invalid Value"
                                onChange={e => setSourceDetails(e.target.value)}
                                validate={{
                                  required: { value: true },
                                }}
                                value={sourceDetails}
                                placeholder="Ex: Received from RTA Person name via Email..."
                              ></AvField>
                            </Col>
                            <Col className="col-12 mt-3">
                              <AvField
                                name="received_on"
                                label="Received On"
                                type="datetime-local"
                                errorMessage="Invalid Value"
                                onChange={e =>
                                  setReceivedOnDate(e.target.value)
                                }
                                validate={{
                                  required: { value: true },
                                }}
                                value={receivedOnDate}
                              ></AvField>
                            </Col>
                          </AvForm>
                        </Row>
                        <Row className="mt-4">
                          <Col>
                            <div className="text-end">
                              <button
                                className="btn btn-primary"
                                disabled={
                                  !base64 || !sourceDetails || !receivedOnDate
                                }
                                onClick={uploadBenpos}
                              >
                                Compare
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId={2}>
                        <Row>
                          <Col md={3}>
                            <div className="mb-3">
                              <h4>{selectedProject?.project_name || "NA"}</h4>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          {benposDiff.length ? (
                            <>
                              <h6 className="text-danger font-size-15 d-inline-flex align-items-center gap-1">
                                <i className="mdi mdi-close-circle-outline font-size-20"></i>{" "}
                                Invalid BenPos!
                              </h6>
                              <p>
                                Mismatched entries are highlighted for your
                                reference. Please verify your BenPos File or
                                contact Admin.
                              </p>
                              <h6 className="mt-3 mb-0 fw-bold font-size-15 d-inline-flex align-items-center gap-1">
                                <i className="mdi mdi-file-remove-outline font-size-20"></i>{" "}
                                Mismatched Entries:
                              </h6>
                              <div className="p-3">
                                <BootstrapTable
                                  keyField="id"
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  columns={BenposDiffColumn}
                                  data={benposDiff}
                                  wrapperClasses={"table-responsive mb-2"}
                                  classes={
                                    "table align-middle table-nowrap table-check"
                                  }
                                  headerWrapperClasses={"table-light"}
                                  bootstrap4
                                  search
                                />
                              </div>
                            </>
                          ) : (
                            <>
                              <h6 className="mt-3 text-success font-size-15 d-inline-flex align-items-center gap-1">
                                <i className="mdi mdi-check-circle font-size-20" />
                                BenPos Matched!
                              </h6>
                              <p>
                                BenPos successfully matched with system records!
                                You can now proceed with the Approval.
                              </p>
                            </>
                          )}
                          <div className="text-end mt-3">
                            <button
                              className="btn btn-outline-primary me-2"
                              onClick={() => {
                                setCompareActiveTab(1)
                              }}
                            >
                              Back
                            </button>
                            <button
                              className="btn btn-success"
                              onClick={toggleCompareModal}
                            >
                              Done
                            </button>
                          </div>
                        </Row>
                      </TabPane>
                    </TabContent>
                  </div>
                </div>
              </ModalBody>
            </Modal>
            {/* Approve Reject Modal */}
            <Modal
              isOpen={approveModal}
              toggle={toggleApproveModal}
              size={"md"}
            >
              <ModalHeader toggle={toggleApproveModal} tag="h4">
                {selectedAction == "APPROVE" ? "Approve" : "Reject"} Bank
                Transfer File
              </ModalHeader>
              <ModalBody>
                <AvForm onValidSubmit={handleReportAction}>
                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <div>Project Name</div>
                        <p
                          className="fw-medium font-size-15"
                          style={{
                            fontSize: "13px",
                          }}
                        >
                          {selectedProject?.project_name || "NA"}
                        </p>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <div>Benpos Date</div>
                        <p
                          className="fw-medium font-size-15"
                          style={{
                            fontSize: "13px",
                          }}
                        >
                          {handleValidDate(benposdate) || "NA"}
                        </p>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <div>Start Date</div>
                        <p
                          className="fw-medium font-size-15"
                          style={{
                            fontSize: "13px",
                          }}
                        >
                          {handleValidDate(startDate) || "NA"}
                        </p>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <div>End Date</div>
                        <p
                          className="fw-medium font-size-15"
                          style={{
                            fontSize: "13px",
                          }}
                        >
                          {handleValidDate(endDate) || "NA"}
                        </p>
                      </div>
                    </Col>
                    {generateData.date && (
                      <Col md={6}>
                        <div className="mb-3">
                          <div>Transfer Date</div>
                          <p
                            className="fw-medium font-size-15"
                            style={{
                              fontSize: "13px",
                            }}
                          >
                            {handleValidDate(generateData.date) || "NA"}
                          </p>
                        </div>
                      </Col>
                    )}
                    {generateData?.bankAccount?.client_name && (
                      <Col md={6}>
                        <div className="mb-3">
                          <div>Client Name</div>
                          <p
                            className="fw-medium font-size-15"
                            style={{
                              fontSize: "13px",
                            }}
                          >
                            {generateData?.bankAccount?.client_name || "NA"}
                          </p>
                        </div>
                      </Col>
                    )}
                    {generateData?.bankAccount?.ifsc_code && (
                      <Col md={6}>
                        <div className="mb-3">
                          <div>IFSC Code</div>
                          <p
                            className="fw-medium font-size-15"
                            style={{
                              fontSize: "13px",
                            }}
                          >
                            {generateData?.bankAccount?.ifsc_code || "NA"}
                          </p>
                        </div>
                      </Col>
                    )}
                    {generateData?.bankAccount?.account_no && (
                      <Col md={6}>
                        <div className="mb-3">
                          <div>Account No.</div>
                          <p
                            className="fw-medium font-size-15"
                            style={{
                              fontSize: "13px",
                            }}
                          >
                            {generateData?.bankAccount?.account_no || "NA"}
                          </p>
                        </div>
                      </Col>
                    )}
                    <div className="mt-3">
                      <AvField
                        name="description"
                        label={
                          selectedAction == "APPROVE" ? "Comment" : "Reason"
                        }
                        type="textarea"
                        errorMessage="Invalid value"
                        validate={{
                          required: { value: true },
                        }}
                        value={actionReason || ""}
                        onChange={e => setActionReason(e.target.value)}
                        rows={3}
                      ></AvField>
                    </div>
                    <div className="mt-4">
                      <AvField
                        name={"consent"}
                        label={
                          <span className={consent ? "" : "text-muted"}>
                            I have verified the Bank Transfer File
                          </span>
                        }
                        type="checkbox"
                        checked={consent}
                        value={consent}
                        onChange={e => setConsent(e.target.checked)}
                      ></AvField>
                    </div>
                    <Col>
                      <div className="text-end gap-4 mt-2">
                        <button
                          type="submit"
                          className={`btn w-100 ${
                            selectedAction == "APPROVE"
                              ? "btn-success"
                              : "btn-danger"
                          } save-user`}
                          disabled={
                            selectedAction == "APPROVE" ? !consent : !consent
                          }
                        >
                          {selectedAction == "APPROVE" ? "Approve" : "Reject"}
                        </button>
                      </div>
                    </Col>
                  </Row>
                </AvForm>
              </ModalBody>
            </Modal>
          </Container>
        ) : (
          <Unauthorized />
        )}
      </div>
    </React.Fragment>
  )
}

export default BankTransfer
