import PropTypes from "prop-types"
import React from "react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { CardBody, Col, Row } from "reactstrap"

const TabTableComponent = ({ data, columns, noData }) => {
  const { SearchBar } = Search

  const defaultSorted = [
    {
      dataField: "created_at",
      order: "desc",
    },
  ]

  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "60",
        value: 60,
      },
      {
        text: "100",
        value: 100,
      },
    ],
  }

  return (
    <CardBody>
      {noData ? (
        noData
      ) : (
        <ToolkitProvider
          keyField="id"
          data={data}
          columns={columns}
          bootstrap4
          search
        >
          {toolkitProps => (
            <React.Fragment>
              <Row className="mb-2 row">
                <Col md={3}>
                  <div className="search-box me-2 mb-1 d-inline-block">
                    <div className="position-relative">
                      <SearchBar {...toolkitProps.searchProps} />
                      <i className="bx bx-search-alt search-icon" />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xl="12">
                  {data.length ? (
                    <BootstrapTable
                      keyField="id"
                      bordered={false}
                      striped={false}
                      defaultSorted={defaultSorted}
                      columns={columns}
                      data={data}
                      pagination={paginationFactory(pageOptions)}
                      wrapperClasses={"table-responsive mb-4 table-fixed"}
                      classes={"table align-middle table-nowrap table-check"}
                      headerWrapperClasses={"table-light"}
                      {...toolkitProps.baseProps}
                    />
                  ) : null}
                </Col>
              </Row>
            </React.Fragment>
          )}
        </ToolkitProvider>
      )}
    </CardBody>
  )
}

TabTableComponent.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.func,
  noData: PropTypes.any,
}

export default TabTableComponent
