import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import Loader from "common/Loader"
import { permissionChecker } from "common/PermissionChecker"
import Unauthorized from "common/Unauthorized"
import { toast } from "react-toastify"
import { axiosInstance } from "ConfigAxioxinstance"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import { Link } from "@material-ui/core"
import { AvField, AvForm } from "availity-reactstrap-validation"
import paginationFactory from "react-bootstrap-table2-paginator"
import { csvDownloadData, handleValidDate } from "constants/common"
import { JSONToCSVConvertor } from "common/jsontocsv"
import BenposProjectCard from "components/Common/BenposProjectCard"
import AuditTrail from "common/AuditTrail/AuditTrail"
import { useParams } from "react-router-dom"
import HeaderWithBackBtn from "components/Common/HeaderWithBackBtn"
import AddRemarkBtn from "components/Remark/AddRemarkBtn"

const TrancheAllocation = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [accessControlData, setAccessControlData] = useState([])
  const [selectedBenpos, setSelectedBenpos] = useState()
  const [editModal, setEditModal] = useState(false)
  const [showError, setShowError] = useState("")
  const [inputTranches, setInputTranches] = useState([])
  const [nilTdsApplicable, setNilTdsApplicable] = useState()
  const [isApproved, setIsApproved] = useState(false)
  const [selectedLogId, setSelectedLogId] = useState("")
  const [auditTrailModal, setAuditTrailModal] = useState(false)
  const [otherTranches, setOtherTranches] = useState({})
  const [allowedBondsPerTranche, setAllowedBondsPerTranche] = useState({})
  const [file, setFile] = useState(null)

  const params = useParams()
  const { benposdate, investmentId } = params

  useEffect(() => {
    const permissions = permissionChecker("/benpos-master")
    setAccessControlData(permissions)
    getApprovalPolicy()
  }, [])

  const getApprovalPolicy = async () => {
    try {
      const response = await axiosInstance.get(
        `/interest-approval?investmentId=${investmentId}&report_type=INTEREST_CALCULATION&status=APPROVED&benpos_date=${benposdate}`
      )
      const respData = response?.data?.data || response?.data
      if (respData && respData.length > 0) {
        setIsApproved(true)
      } else {
        setIsApproved(false)
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  const masterData = async () => {
    const url = `benpos-report/tranche-allocation-listing?investmentId=${investmentId}&benpos_date=${benposdate}`
    try {
      setLoading(true)
      const response = await axiosInstance.get(url)
      if (response) {
        const resData = response?.data?.data || response?.data
        setData(resData)
        const allowedBondsPerTranche = {}

        resData[0]?.tranches.forEach(
          tranche => (allowedBondsPerTranche[tranche.id] = tranche.lot_size)
        )
        resData[1]?.tranches.forEach(
          tranche => (allowedBondsPerTranche[tranche.id] -= tranche.lot_size)
        )
        resData
          .at(-2)
          ?.tranches.forEach(
            tranche => (allowedBondsPerTranche[tranche.id] -= tranche.lot_size)
          )

        setAllowedBondsPerTranche(allowedBondsPerTranche)
        setOtherTranches(response?.data?.other_tranches)
      }
    } catch (error) {
      toast.error(error?.message)
    } finally {
      setLoading(false)
    }
  }

  const defaultSorted = [
    {
      dataField: "created_at",
      order: "desc",
    },
  ]

  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    disablePageTitle: true,
    sizePerPageList: [
      // {
      //   text: "10",
      //   value: 10,
      // },
      {
        text: "30",
        value: 30,
      },
      // {
      //   text: "60",
      //   value: 60,
      // },
      // {
      //   text: "100",
      //   value: 100,
      // },
    ],
  }

  const { SearchBar } = Search
  useEffect(() => {
    masterData()
  }, [])

  const getTooltip = row => {
    switch (row.displayName) {
      // case "No. of Bonds":
      //   return "Total Bonds (All Tranches)"
      // case "Common":
      //   return "Total Bonds categorized as COMMON (Absolute Matching)"
      case "Sub-Total":
        return "Total of BENPOS, COMMON+BENPOS and COMMON+SYSTEM"
      case "Grand-Total":
        return "Total Bonds in Uploaded BenPos"
      default:
        if (row?.category === "COMMON_SYSTEM") {
          return "COMMON+SYSTEM: No. of Bonds in System > No. of Bonds in BenPos"
        } else {
          return "COMMON+BENPOS: No. of Bonds in System < No. of Bonds in BenPos"
        }
    }
  }

  const TableColumns = download => {
    let columns = [
      {
        dataField: "action",
        isDummyField: true,
        text: "Action",
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, order) => {
          if (
            !(
              order?.displayName === "No. of Bonds" ||
              order?.displayName === "Common" ||
              order?.displayName === "Sub-Total" ||
              order?.displayName === "Grand-Total"
            )
          ) {
            return !isApproved ? (
              <div className="d-flex gap-3">
                <Link
                  to="#"
                  className="text-success"
                  onClick={() => {
                    toggleEditModal(order)
                  }}
                >
                  <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                </Link>
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </div>
            ) : (
              "NA"
            )
          }
          return ""
        },
      },
      {
        dataField: "displayName",
        text: "Particulars",
        sort: false, // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => {
          if (
            cellContent === "No. of Bonds" ||
            cellContent === "Common" ||
            cellContent === "Sub-Total" ||
            cellContent === "Grand-Total"
          ) {
            return (
              <b>
                {cellContent === "No. of Bonds"
                  ? row?.escape
                    ? "No. of Bonds (Benpos)"
                    : "No. of Bonds (All Tranches)"
                  : cellContent === "Common"
                  ? "Common (Absolute Matching)"
                  : cellContent}{" "}
                {!(
                  cellContent === "No. of Bonds" || cellContent === "Common"
                ) && (
                  <span
                    className={`text-end align-middle d-inline-flex align-items-center text-primary justify-content-center`}
                  >
                    <i
                      className="mdi mdi-information-outline font-size-15 text-secondary"
                      id={`ifsctooltip-${row.id}`}
                      style={{ cursor: "pointer" }}
                    />
                    <UncontrolledTooltip target={`#ifsctooltip-${row.id}`}>
                      {getTooltip(row)}
                    </UncontrolledTooltip>
                  </span>
                )}
              </b>
            )
          } else {
            return (
              <span>
                {cellContent}
                {row?.category === "COMMON_SYSTEM" ||
                row?.category === "COMMON_BENPOS" ? (
                  <span
                    className={`text-end align-middle d-inline-flex align-items-center text-primary ms-1 justify-content-center`}
                  >
                    <i
                      className="mdi mdi-information-outline font-size-15 text-secondary"
                      id={`ifsctooltip-${row.id}`}
                      style={{ cursor: "pointer" }}
                    />
                    <UncontrolledTooltip target={`#ifsctooltip-${row.id}`}>
                      {getTooltip(row)}
                    </UncontrolledTooltip>
                  </span>
                ) : (
                  <></>
                )}
              </span>
            )
          }
        },
      },
    ]

    let totalCol = {
      dataField: "total",
      text: "Total Bonds",
      sort: false,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => {
        if (
          row?.displayName === "No. of Bonds" ||
          row?.displayName === "Common" ||
          row?.displayName === "Sub-Total" ||
          row?.displayName === "Grand-Total"
        ) {
          return <b>{cellContent + (row?.common_bonds || 0)}</b>
        } else {
          return <span>{cellContent + (row?.common_bonds || 0)}</span>
        }
      },
    }

    const commonBonds = {
      dataField: "common_bonds",
      text: "Common",
      sort: false,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => {
        if (
          row?.displayName === "No. of Bonds" ||
          row?.displayName === "Common"
        ) {
          return <b>-</b>
        } else if (row?.displayName === "Sub-Total") {
          return <b>{cellContent || 0}</b>
        } else if (row?.displayName === "Grand-Total") {
          return <b>-</b>
        } else {
          return <span>{cellContent || 0}</span>
        }
      },
    }

    let allocatedCol = {
      dataField: "total",
      text: "To Be Allocated",
      sort: false,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => {
        if (
          row?.displayName === "No. of Bonds" ||
          row?.displayName === "Common"
        ) {
          return <b>-</b>
        } else if (row?.displayName === "Sub-Total") {
          return (
            <b>
              {cellContent -
                row?.tranches.reduce(
                  (acc, item) => acc + (item?.lot_size || 0),
                  0
                )}
            </b>
          )
        } else if (row?.displayName === "Grand-Total") {
          return <b>-</b>
        } else {
          return (
            <span>
              {/* {row?.tranches.reduce(
                (acc, item) => acc + (item?.lot_size || 0),
                0
              )} */}
              {cellContent -
                row?.tranches.reduce(
                  (acc, item) => acc + (item?.lot_size || 0),
                  0
                )}
            </span>
          )
        }
      },
    }
    // Adding Tranch Headings
    let tranchHeaders =
      data[0]?.tranches?.map(item => ({
        dataField: item?.investment_lot_name,
        text: item?.investment_lot_name,
        sort: false,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => {
          const data =
            row?.tranches?.find(
              el => el?.investment_lot_name === item?.investment_lot_name
            )?.lot_size || 0
          if (
            row?.displayName === "No. of Bonds" ||
            row?.displayName === "Common" ||
            row?.displayName === "Sub-Total" ||
            row?.displayName === "Grand-Total"
          ) {
            return <b>{row.escape ? "-" : data}</b>
          } else {
            return <span>{data}</span>
          }
        },
      })) || []
    const otherTranchesCol = {
      dataField: "total",
      text: "Other Tranches",
      sort: false,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => {
        if (
          row?.displayName === "No. of Bonds" ||
          row?.displayName === "Common" ||
          row?.displayName === "Grand-Total"
        ) {
          return <b>{row.escape ? "-" : otherTranches?.total}</b>
        } else if (row?.displayName === "Sub-Total") {
          return <b>-</b>
        } else {
          return <span>-</span>
        }
      },
    }

    columns.push(totalCol)
    columns.push(commonBonds)
    columns.push(allocatedCol)
    columns.push(...tranchHeaders)
    if (otherTranches?.total) {
      columns.push(otherTranchesCol)
    }

    let tdsHeader = {
      dataField: "benpos_nil_tds_applicable",
      text: "NIL TDS",
      sort: false,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => {
        return row.system_nil_tds_applicable === undefined
          ? row.benpos_nil_tds_applicable
          : row.system_nil_tds_applicable
      },
    }

    let nilTDSProofHeader = {
      dataField: "nil_tds_proof",
      text: "NIL TDS Proof",
      sort: false,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => {
        if (cellContent) {
          return (
            <Link
              to="#"
              onClick={() => {
                getSignedUrlHandler(cellContent)
              }}
            >
              <i className="mdi mdi-file-document font-size-20" />
            </Link>
          )
        } else return ""
      },
    }

    let logsHeader = {
      dataField: "",
      text: "Logs",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => {
        if (
          !(
            row?.displayName === "No. of Bonds" ||
            row?.displayName === "Common" ||
            row?.displayName === "Sub-Total" ||
            row?.displayName === "Grand-Total"
          )
        )
          return (
            <>
              <Link
                to="#"
                onClick={() => {
                  setSelectedLogId(row.id)
                  setAuditTrailModal(!auditTrailModal)
                }}
              >
                <i
                  className="mdi mdi-content-paste font-size-18"
                  id="logsToolTip"
                />
              </Link>
              <UncontrolledTooltip placement="top" target="logsToolTip">
                Logs
              </UncontrolledTooltip>
            </>
          )
      },
    }

    columns.push(tdsHeader, nilTDSProofHeader, logsHeader)

    return columns
  }

  const toggleEditModal = data => {
    setFile(null)
    setSelectedBenpos(data)
    setInputTranches(data?.tranches)
    if (data?.system_nil_tds_applicable === undefined) {
      setNilTdsApplicable(data?.benpos_nil_tds_applicable)
    }
    setEditModal(!editModal)
  }

  const uploadFileHandler = async () => {
    try {
      const formData = new FormData()
      formData.append("file", file)
      const uploadResponse = await axiosInstance.post(
        `/file-upload/?type=nil_tds_applicable`,
        formData
      )
      return {
        file_url: uploadResponse.data[0].name,
        file_name: file.name,
        mime_type: file.type,
        file_size: file.size,
      }
    } catch (error) {
      toast.error(error?.message)
      throw error
    }
  }

  const onModalSubmitHandler = async (e, values) => {
    try {
      setLoading(true)

      const postData = []

      for (let item of inputTranches) {
        postData.push({
          investmentLotId: item.id,
          benposId: selectedBenpos?.id,
          bonds: item?.lot_size,
        })
      }
      let uploadedProof = ""
      if (file) {
        const uploadedFile = await uploadFileHandler()
        uploadedProof = uploadedFile.file_url
      }

      const payload = {
        benpos_nil_tds_applicable:
          selectedBenpos?.system_nil_tds_applicable === undefined
            ? nilTdsApplicable
            : selectedBenpos?.system_nil_tds_applicable,
      }

      if (nilTdsApplicable && selectedBenpos?.nil_tds_proof && !file) {
        payload.nil_tds_proof = undefined
      } else {
        payload.nil_tds_proof = file ? uploadedProof : null
      }

      await axiosInstance.patch(`benpos/${selectedBenpos?.id}`, payload)

      await axiosInstance.post("benpos-tranche-allocation", postData)

      masterData()
      toggleEditModal()
      toast.success("Tranche Allocation Successful")
    } catch (error) {
      toast.error(error?.message)
    } finally {
      setLoading(false)
    }
  }

  const handleValidInputTranches = (e, item) => {
    const newValue = Number(e.target.value || 0)

    if (Array.isArray(inputTranches)) {
      const updatedTranches = inputTranches.map(tranche =>
        tranche.id === item.id ? { ...tranche, lot_size: newValue } : tranche
      )
      setInputTranches(updatedTranches)
    }
  }

  const validationChecker = () => {
    const no_of_bonds = data?.find(item => item.displayName === "No. of Bonds")
    const common = data?.find(item => item.displayName === "Common")
    const subTotal = data?.find(item => item.displayName === "Sub-Total")

    const inputTotal = inputTranches?.reduce(
      (acc, item) => acc + item.lot_size,
      0
    )

    inputTranches.forEach(item => {
      // commmon + all investor particular tranche sum + input <= no of bonds
      const tranchCommon = common.tranches.find(
        el => el.investment_lot_name === item.investment_lot_name
      )
      const trancheNoofBonds = no_of_bonds.tranches.find(
        el => el.investment_lot_name === item.investment_lot_name
      )

      const tranchSubTotal = subTotal.tranches.find(
        el => el.investment_lot_name === item.investment_lot_name
      )
      if (
        tranchCommon.lot_size + tranchSubTotal.total + item.lot_size !=
        trancheNoofBonds.lot_size
      ) {
        setShowError(true)
      } else {
        setShowError(false)
      }
    })
    // Row Validation
    if (inputTotal != selectedBenpos?.total) {
      setShowError(true)
    } else {
      setShowError(false)
    }
  }

  useEffect(() => {
    if (inputTranches && inputTranches?.length > 0) {
      validationChecker()
    }
  }, [inputTranches])

  const onSelectFile = e => {
    setFile(e.target.files[0])
  }

  const getSignedUrlHandler = async file => {
    try {
      setLoading(true)
      const response = await axiosInstance.post("/get-signed-url", {
        fileName: file,
      })
      window.open(response?.data)
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        {accessControlData?.permissions?.BENPOS_TRANCHE_ALLOCATION ? (
          <Container fluid>
            <HeaderWithBackBtn title={"Tranche & TDS Allocation"} />
            <hr />
            <BenposProjectCard
              investmentId={investmentId}
              benpos_date={benposdate}
            />
            <Row>
              <Col xs="12">
                <Row className="mb-2 row justify-content-end align-items-center">
                  <div className="col-auto">
                    <AddRemarkBtn
                      benpos_date={benposdate}
                      investmentId={investmentId}
                      report_type={"TRANCHE_AND_TDS_ALLOCATION"}
                    />
                    {/* <Button
                      type="button"
                      color="primary"
                      className="btn-rounded mx-2 mb-2"
                      onClick={downloadData}
                    >
                      <i className="mdi mdi-arrow-down-bold-circle"></i>{" "}
                      Download
                    </Button> */}
                  </div>
                </Row>
                <Card>
                  <CardBody>
                    <ToolkitProvider
                      keyField="id"
                      data={data}
                      columns={TableColumns()}
                      bootstrap4
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row className="mb-2 row">
                            <Col md={3}>
                              <div className="search-box me-2 mb-1 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar {...toolkitProps.searchProps} />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>
                          </Row>
                          {/* Table and Modal start */}
                          <Row>
                            <Col xl="12">
                              {data.length ? (
                                <BootstrapTable
                                  keyField="id"
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  columns={TableColumns()}
                                  data={data}
                                  pagination={paginationFactory(pageOptions)}
                                  wrapperClasses={
                                    "table-responsive mb-4 table-fixed"
                                  }
                                  classes={
                                    "table align-middle table-nowrap table-check"
                                  }
                                  headerWrapperClasses={"table-light"}
                                  {...toolkitProps.baseProps}
                                />
                              ) : null}
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Modal isOpen={editModal} toggle={toggleEditModal}>
              <ModalHeader toggle={toggleEditModal} tag="h4">
                Tranche Allocation
              </ModalHeader>
              <ModalBody>
                <AvForm onValidSubmit={onModalSubmitHandler}>
                  <Row form>
                    <Col className="col-12">
                      <div className="row">
                        <Col className="col-6">
                          <h6>Investor Name</h6>
                          <p>{selectedBenpos?.displayName}</p>
                        </Col>
                        <Col className="col-6">
                          <h6>To Be Allocated</h6>
                          <p
                            className={
                              selectedBenpos?.total -
                                (inputTranches?.length
                                  ? inputTranches?.reduce(
                                      (acc, item) =>
                                        acc + (item?.lot_size || 0),
                                      0
                                    )
                                  : selectedBenpos?.tranches?.reduce(
                                      (acc, item) =>
                                        acc + (item?.lot_size || 0),
                                      0
                                    )) <
                                0 ||
                              inputTranches?.some(item => item.lot_size < 0)
                                ? "text-danger"
                                : ""
                            }
                          >
                            {selectedBenpos?.total -
                              (inputTranches?.length
                                ? inputTranches?.reduce(
                                    (acc, item) => acc + (item?.lot_size || 0),
                                    0
                                  )
                                : selectedBenpos?.tranches?.reduce(
                                    (acc, item) => acc + (item?.lot_size || 0),
                                    0
                                  ))}{" "}
                            Bonds
                          </p>
                        </Col>

                        {selectedBenpos?.tranches?.map((item, index) => {
                          return (
                            <div
                              className="mb-3 col-6"
                              key={item?.investment_lot_name}
                            >
                              <AvField
                                name={item?.id}
                                label={item?.investment_lot_name}
                                type="number"
                                value={item?.lot_size || "0"}
                                onChange={e =>
                                  handleValidInputTranches(e, item)
                                }
                                errorMessage="This field is required"
                                validate={{
                                  required: { value: true },
                                }}
                                className={
                                  inputTranches?.find(el => el.id === item.id)
                                    ?.lot_size < 0
                                    ? "text-danger"
                                    : ""
                                }
                              />
                            </div>
                          )
                        })}
                        {selectedBenpos?.system_nil_tds_applicable ===
                          undefined && (
                          <>
                            <div className="mb-1">
                              <AvField
                                name={"tds_status"}
                                label="NIL TDS"
                                type="checkbox"
                                value={nilTdsApplicable}
                                onChange={e => {
                                  setNilTdsApplicable(e.target.checked)
                                }}
                              />
                            </div>
                            {nilTdsApplicable && (
                              <div className="mb-3">
                                <Label
                                  htmlFor="formFile"
                                  className="form-label"
                                >
                                  Upload Nil Tds Applicable Proof
                                </Label>
                                <Input
                                  className="form-control"
                                  type="file"
                                  id="formFile"
                                  validate={{
                                    required: { value: true },
                                  }}
                                  onChange={onSelectFile}
                                />
                              </div>
                            )}
                            {nilTdsApplicable && selectedBenpos?.nil_tds_proof && (
                              <Link
                                to="#"
                                className="text-primary d-flex align-items-center"
                                onClick={() =>
                                  getSignedUrlHandler(
                                    selectedBenpos?.nil_tds_proof
                                  )
                                }
                              >
                                <div
                                  className="mb-3 py-1 px-2 rounded-pill border d-inline-block border-primary text-primary font-size-12"
                                  style={{ cursor: "pointer" }}
                                >
                                  <svg
                                    viewBox="0 0 24 24"
                                    width="18px"
                                    fill="#556ee6"
                                  >
                                    <path d="M6,2A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6M6,4H13V9H18V20H6V4M8,12V14H16V12H8M8,16V18H13V16H8Z" />
                                  </svg>{" "}
                                  Uploaded NIL TDS Applicable Proof
                                </div>
                              </Link>
                            )}
                          </>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-end">
                        <Button
                          type="submit"
                          color="success"
                          className="save-user"
                          disabled={
                            showError ||
                            (nilTdsApplicable &&
                              !file &&
                              !selectedBenpos?.nil_tds_proof)
                          }
                        >
                          Save
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </AvForm>
              </ModalBody>
            </Modal>
            <AuditTrail
              recordId={selectedLogId}
              modal={auditTrailModal}
              setModal={setAuditTrailModal}
            />
          </Container>
        ) : (
          <Unauthorized />
        )}
      </div>
    </React.Fragment>
  )
}

export default TrancheAllocation
