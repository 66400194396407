import PropTypes from "prop-types"
import React from "react"
import { Link } from "react-router-dom"

const ReportStatus = ({ report, reportsData }) => {
  const getReportStatus = () => {
    if (report.policies && report.policies.length) {
      if (
        report.policies.every(
          item => item.interest_approval?.status === "APPROVED"
        )
      ) {
        return {
          status: "Approved",
          color: "success",
          icon: "mdi-check-circle-outline",
        }
      } else if (
        report.policies.some(
          item => item.interest_approval?.status === "REJECTED"
        )
      ) {
        return {
          status: "Rejected",
          color: "danger",
          icon: "mdi-close-circle-outline",
        }
      }
      if (report.policies.some(item => !item.interest_approval)) {
        if (report.label === "Bank Transfer File" && report.isLocked) {
          return {
            status: "Not Generated",
            color: "secondary",
            icon: "mdi-clock-outline",
          }
        } else {
          return {
            status: "Pending Approval",
            color: "warning",
            icon: "mdi-clock-outline",
          }
        }
      }
    }

    // For Exception Report/ Tranche & TDS Allocation
    if (
      (report.label === "Exception Report" ||
        report.label === "Tranche & TDS Allocation") &&
      reportsData
        .find(item => item.label === "Interest Calculation")
        ?.policies.some(el => el?.interest_approval?.status === "APPROVED")
    ) {
      return {
        status: "Completed",
        color: "success",
        icon: "mdi-check-circle-outline",
      }
    } else if (
      report.label === "Exception Report" ||
      report.label === "Tranche & TDS Allocation"
    ) {
      return {
        status: "Action Required",
        color: "warning",
        icon: "mdi-information-outline",
      }
    }

    return {
      status: "Generated",
      color: "secondary",
      icon: "mdi-check-circle-outline",
    }
  }

  const { status, color, icon } = getReportStatus()

  return (
    <div className="d-flex flex-column gap-2 align-items-end">
      <div
        className={`d-flex align-items-center border rounded border-${color} px-2`}
      >
        <i className={`mdi ${icon} text-${color} font-size-17`}></i>
        <p className={`mb-0 ms-1 font-size-12 text-${color}`}>{status}</p>
      </div>

      {report.isLocked ? (
        <p className="d-flex align-items-center text-secondary">
          <p className="mb-0">View Report</p>

          <i className="mdi mdi-lock ms-1" style={{ fontSize: 15 }}></i>
        </p>
      ) : (
        <Link className="d-flex align-items-center" to={report.path}>
          <p className="mb-0">View Report</p>
          <i className="mdi mdi-chevron-right" style={{ fontSize: 16 }}></i>
        </Link>
      )}
    </div>
  )
}

ReportStatus.propTypes = {
  report: PropTypes.array,
  reportsData: PropTypes.array,
}

export default ReportStatus
