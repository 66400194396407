import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import { JSONToCSVConvertor } from "common/jsontocsv"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"
import { csvDownloadData, investmentData } from "constants/common"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import Loader from "common/Loader"
import { permissionChecker } from "common/PermissionChecker"
import Unauthorized from "common/Unauthorized"
import { AvField, AvForm } from "availity-reactstrap-validation"
import ReactSelect from "constants/ReactSelect"
import { Link } from "react-router-dom"

const InvestmentBankAccount = () => {
  // Loader State
  const [loading, setLoading] = useState(false)

  // Master Data State
  const [tableData, setTableData] = useState([])
  const [investmentList, setInvestmentList] = useState([])

  // Modal State
  const [addEditModal, setAddEditModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)

  const [selectedData, setSelectedData] = useState(null)
  const [selectedRowId, setSelectedRowId] = useState(null)
  // SSP State
  const [limit, setLimit] = useState(10)
  const [skip, setSkip] = useState(0)
  const [total, setTotal] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)

  const [investmentFilter, setInvestmentFilter] = useState("")

  // Other States

  const [accessControlData, setAccessControlData] = useState([])

  useEffect(() => {
    const permissions = permissionChecker()
    setAccessControlData(permissions)
  }, [])
  const defaultSorted = [
    {
      dataField: "created_at",
      order: "desc",
    },
  ]

  const toggleAddEditModal = data => {
    setAddEditModal(!addEditModal)
    if (data) {
      setSelectedRowId(data?.id)
      setSelectedData({
        account_no: data?.account_no,
        account_type: data?.account_type,
        client_name: data?.client_name,
        ifsc_code: data?.ifsc_code,
        investmentId: data?.investmentId,
        narration: data?.narration,
      })
    }
  }

  const toggleDeleteModal = data => {
    setDeleteModal(!deleteModal)
    setSelectedRowId(data?.id)
  }

  // Table Columns
  const TableColumns = download => {
    const columns = [
      {
        dataField: "",
        isDummyField: true,
        text: "Actions",
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, order) => (
          <>
            {accessControlData?.permissions?.INVESTMENT_BANK_ACCOUNT_EDIT && (
              <Link
                to="#"
                className="text-success me-2"
                onClick={() => toggleAddEditModal(order)}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
              </Link>
            )}
            {accessControlData?.permissions?.INVESTMENT_BANK_ACCOUNT_DELETE && (
              <Link
                to="#"
                className="text-danger"
                onClick={() => toggleDeleteModal(order)}
              >
                <i className="mdi mdi-delete font-size-18" id="edittooltip" />
              </Link>
            )}
            {!accessControlData?.permissions?.INVESTMENT_BANK_ACCOUNT_EDIT &&
              !accessControlData?.permissions?.INVESTMENT_BANK_ACCOUNT_DELETE &&
              "NA"}
          </>
        ),
      },
      {
        dataField: "investment.project_name",
        text: "Project Name",
        sort: true,
      },
      { dataField: "account_no", text: "Account No.", sort: true },
      { dataField: "account_type", text: "Account Type", sort: true },
      { dataField: "client_name", text: "Client Name", sort: true },
      { dataField: "ifsc_code", text: "IFSC Code", sort: true },
      { dataField: "narration", text: "Narration", sort: true },
    ]
    return columns
  }

  const GetInvestmentList = async () => {
    try {
      const invResponse = await investmentData()
      if (invResponse) {
        setInvestmentList(invResponse?.data?.data || invResponse?.data)
      }
    } catch (error) {
      toast.error(error?.message)
    }
  }

  const filterUrl = `${
    investmentFilter?.id ? `&investmentId=${investmentFilter.id}` : ""
  }`

  // Main Function - Fetch Table Data
  const masterData = async () => {
    setTableData([])
    setLoading(true)

    const getUrl = `/investment-bank-account?$limit=${limit}&$skip=${skip}${filterUrl}`

    try {
      const response = await axiosInstance.get(getUrl)

      if (response) {
        setTotal(response.data.total)
        const data = response?.data?.data || response?.data
        setTableData(data)
        let pages = Math.ceil(
          (response.data?.total || response.total) /
            (response.data?.limit || response.limit)
        )
        setTotalPage(pages)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
  }

  const handleValidSubmit = async () => {
    if (!selectedData?.investmentId) {
      toast.error("Please select a project")
      return
    }
    try {
      setLoading(true)
      const response = selectedRowId
        ? await axiosInstance.patch(
            `/investment-bank-account/${selectedRowId}`,
            selectedData
          )
        : await axiosInstance.post("/investment-bank-account", selectedData)
      if (response) {
        masterData()
        toast.success(`Successfully ${selectedRowId ? "Updated" : "Added"}`)
        toggleAddEditModal(null)
        setSelectedRowId(null)
      }
    } catch (error) {
      console.log(error.message)
    } finally {
      setLoading(false)
    }
  }

  const deleteHandler = async () => {
    try {
      setLoading(true)
      const response = await axiosInstance.delete(
        `/investment-bank-account/${selectedRowId}`
      )

      if (response) {
        masterData()
        toast.success(`Successfully Deleted`)
        setSelectedRowId(null)
        toggleDeleteModal()
      }
    } catch (error) {
      console.log(error.message)
    } finally {
      setLoading(false)
    }
  }

  const downloadData = async () => {
    try {
      setLoading(true)

      const getUrl = `/investment-bank-account?${filterUrl}`
      const response = await axiosInstance.get(getUrl)
      if (response) {
        const data = response?.data?.data || response?.data
        const csvTableHeaders = TableColumns(true).splice(1)
        const str = JSON.stringify(data)
        const arr = JSON.parse(str)
        const downloadableArr = csvDownloadData(csvTableHeaders, arr)
        JSONToCSVConvertor(downloadableArr, "Investment Bank Account", true)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      toast.error(error?.message)
    }
  }

  // SSP Filters
  const handleFilterChange = async (e, key) => {
    switch (key) {
      case "projectName":
        setInvestmentFilter(e)
        break
      case "limit":
        setLimit(Number(e.target.value))
        break
      default:
        break
    }
    setSkip(0)
    setCurrentPage(1)
  }

  // Pagination handler - Start
  useEffect(async () => {
    masterData()
    GetInvestmentList()
  }, [limit, skip, investmentFilter])

  const onChangePagination = e => {
    const { value } = e.target
    if (value < 1 || value > totalPage) {
      return
    }
    setCurrentPage(value)
    if (value) {
      setSkip((value - 1) * limit)
    }
  }
  const handleNext = prev => {
    setSkip(prev + limit)
    let page = (prev + limit) / limit + 1
    setCurrentPage(page)
  }
  const handlePrevious = prev => {
    setSkip(prev - limit)
    let page = (prev - limit) / limit + 1
    setCurrentPage(page)
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        {accessControlData?.permissions?.INVESTMENT_BANK_ACCOUNT_VIEW ? (
          <Container fluid>
            <Breadcrumbs breadcrumbItem={accessControlData?.pageName} />
            <Row>
              <Col xs="12">
                <Row className="mb-2 d-flex justify-content-between">
                  <Col md={2}>
                    <select
                      className="form-select w-75"
                      value={limit}
                      onChange={e => handleFilterChange(e, "limit")}
                    >
                      {[10, 30, 50, 100].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                          Show {pageSize}
                        </option>
                      ))}
                    </select>
                  </Col>
                  <Col className="d-flex justify-content-end">
                    {accessControlData?.permissions
                      ?.INVESTMENT_BANK_ACCOUNT_ADD && (
                      <div className="col-auto">
                        <Button
                          type="button"
                          color="success"
                          className="btn-rounded  mb-2 me-2"
                          onClick={toggleAddEditModal}
                        >
                          <i className="mdi mdi-plus me-1" />{" "}
                          {
                            accessControlData?.permissions
                              ?.INVESTMENT_BANK_ACCOUNT_ADD
                          }
                        </Button>
                      </div>
                    )}
                    {accessControlData?.permissions
                      ?.INVESTMENT_BANK_ACCOUNT_DOWNLOAD && (
                      <Button
                        type="button"
                        color="primary"
                        className="btn-rounded mb-2 me-2"
                        onClick={downloadData}
                      >
                        <i className="mdi mdi-arrow-down-bold-circle"></i>{" "}
                        {
                          accessControlData?.permissions
                            ?.INVESTMENT_BANK_ACCOUNT_DOWNLOAD
                        }
                      </Button>
                    )}
                  </Col>
                </Row>
                <Card>
                  <CardBody>
                    <ToolkitProvider
                      keyField="id"
                      data={tableData}
                      columns={TableColumns()}
                      bootstrap4
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row className="mb-2 row">
                            <Col md={3}>
                              <div className="mb-3">
                                <label>Project Name</label>
                                <ReactSelect
                                  users={investmentList}
                                  setSelectedOption={e =>
                                    handleFilterChange(e, "projectName")
                                  }
                                  multiOptionLabel={true}
                                  optionLabelKeys={["project_name"]}
                                  isClearable={true}
                                />
                              </div>
                            </Col>
                          </Row>
                          {/* Table and Modal start */}
                          <Row>
                            <Col xl="12">
                              <BootstrapTable
                                keyField="id"
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                columns={TableColumns()}
                                data={tableData}
                                wrapperClasses={"table-responsive mb-4"}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                {...toolkitProps.baseProps}
                              />
                            </Col>
                          </Row>
                          {/* Table and Modal End */}
                          {/* SSP Pagination Start */}
                          <Row className="justify-content-md-space-between justify-content-center align-items-center">
                            <Col className="col-12 col-md-auto mb-3">
                              {`Showing ${total ? skip + 1 + " to" : ""} ${
                                limit > total || limit + skip > total
                                  ? total
                                  : limit + skip
                              } rows of ${total}
                 `}
                            </Col>
                            <Col>
                              <Row className="justify-content-md-end justify-content-center align-items-center">
                                <Col className="col-md-auto">
                                  <div className="d-flex gap-1">
                                    <Button
                                      color="primary"
                                      onClick={() => handlePrevious(limit)}
                                      disabled={currentPage == 1}
                                    >
                                      {"<<"}
                                    </Button>
                                    <Button
                                      color="primary"
                                      onClick={() => handlePrevious(skip)}
                                      disabled={currentPage == 1}
                                    >
                                      {"<"}
                                    </Button>
                                  </div>
                                </Col>
                                <Col className="col-md-auto d-none d-md-block">
                                  Page{" "}
                                  <strong>{`${
                                    currentPage ? currentPage : 1
                                  } of ${totalPage ? totalPage : 1}`}</strong>
                                </Col>
                                <Col className="col-md-auto">
                                  <Input
                                    type="number"
                                    min={1}
                                    style={{ width: 70 }}
                                    max={total == 0 ? 1 : totalPage}
                                    value={currentPage || 1}
                                    defaultValue={1}
                                    onChange={onChangePagination}
                                    disabled={total == 0}
                                  />
                                </Col>

                                <Col className="col-md-auto">
                                  <div className="d-flex gap-1">
                                    <Button
                                      color="primary"
                                      onClick={() => handleNext(skip)}
                                      disabled={
                                        currentPage == totalPage || total == 0
                                      }
                                    >
                                      {">"}
                                    </Button>
                                    <Button
                                      color="primary"
                                      onClick={() =>
                                        handleNext((totalPage - 2) * limit)
                                      }
                                      disabled={
                                        currentPage == totalPage || total == 0
                                      }
                                    >
                                      {">>"}
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          {/* SSP Pagination End */}
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Modal isOpen={addEditModal} toggle={toggleAddEditModal}>
              <ModalHeader toggle={toggleAddEditModal} tag="h4">
                {selectedRowId ? "Edit" : "Add"} Bank Account
              </ModalHeader>
              <ModalBody>
                <AvForm onValidSubmit={handleValidSubmit}>
                  <Row form>
                    <Col className="col-12 mb-3">
                      <div className="mb-3">
                        <label>Project Name</label>
                        <ReactSelect
                          name="investmentId"
                          users={investmentList}
                          setSelectedOption={e =>
                            setSelectedData(prev => ({
                              ...prev,
                              investmentId: e?.id,
                            }))
                          }
                          selectedOption={investmentList.find(
                            item => selectedData?.investmentId === item.id
                          )}
                          multiOptionLabel={true}
                          optionLabelKeys={["project_name"]}
                          isClearable={true}
                        />
                      </div>
                    </Col>
                    <Col className="col-12 mb-3">
                      <AvField
                        name="account_no"
                        label="Account No"
                        type="number"
                        errorMessage="Invalid value"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={e =>
                          setSelectedData(prev => ({
                            ...prev,
                            account_no: e.target.value,
                          }))
                        }
                        value={selectedData?.account_no}
                      ></AvField>
                    </Col>
                    <Col className="col-12 mb-3">
                      <AvField
                        name="account_type"
                        label="Account Type"
                        type="text"
                        errorMessage="Invalid value"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={e =>
                          setSelectedData(prev => ({
                            ...prev,
                            account_type: e.target.value,
                          }))
                        }
                        value={selectedData?.account_type}
                      ></AvField>
                    </Col>
                    <Col className="col-12 mb-3">
                      <AvField
                        name="client_name"
                        label="Client Name"
                        type="text"
                        errorMessage="Invalid value"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={e =>
                          setSelectedData(prev => ({
                            ...prev,
                            client_name: e.target.value,
                          }))
                        }
                        value={selectedData?.client_name}
                      ></AvField>
                    </Col>
                    <Col className="col-12 mb-3">
                      <AvField
                        name="ifsc_code"
                        label="IFSC Code"
                        type="text"
                        errorMessage="Invalid value"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={e =>
                          setSelectedData(prev => ({
                            ...prev,
                            ifsc_code: e.target.value,
                          }))
                        }
                        value={selectedData?.ifsc_code}
                      ></AvField>
                    </Col>
                    <Col className="col-12 mb-3">
                      <AvField
                        name="narration"
                        label="Narration"
                        type="text"
                        errorMessage="Invalid value"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={e =>
                          setSelectedData(prev => ({
                            ...prev,
                            narration: e.target.value,
                          }))
                        }
                        value={selectedData?.narration}
                      ></AvField>
                    </Col>
                  </Row>
                  <Col className="mt-3 col-12 d-flex justify-content-end">
                    <button type="submit" className="btn btn-success">
                      Submit
                    </button>
                  </Col>
                </AvForm>
              </ModalBody>
            </Modal>
            <Modal isOpen={deleteModal} toggle={toggleDeleteModal}>
              <ModalHeader
                toggle={toggleDeleteModal}
                tag="h4"
                className="text-center"
              >
                Are you sure want to delete
              </ModalHeader>
              <ModalBody>
                <Col className="mt-3 col-12">
                  <div className="d-flex justify-content-end gap-2">
                    <Button
                      color="secondary"
                      outline
                      onClick={toggleDeleteModal}
                    >
                      Cancel
                    </Button>
                    <Button color={"danger"} onClick={deleteHandler}>
                      Delete
                    </Button>
                  </div>
                </Col>
              </ModalBody>
            </Modal>
          </Container>
        ) : (
          <Unauthorized />
        )}
      </div>
    </React.Fragment>
  )
}

export default InvestmentBankAccount
