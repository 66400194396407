import React from "react"
import Select from "react-select"
import PropTypes from "prop-types"
import { getOptionLabel, getSearchOptionLabel } from "./common"

export default function ReactSelect({
  users: optionList,
  setSelectedOption,
  selectedOption,
  roles,
  transaction,
  investment,
  multiOptionLabel,
  optionLabelKeys,
  isDisabled = false,
  isClearable = false,
  searchValue,
  setSearchValue,
}) {
  const customComponent = option => {
    if (option.name && option.count) {
      return (
        <div className="d-flex justify-content-between align-items-center">
          <span className="d-block">{option.name}</span>
          <span
            className="bg-primary text-white ms-2 font-size-12 text-center rounded-circle d-flex justify-content-center align-items-center"
            style={{
              minWidth: "20px",
              minheight: "20px",
            }}
          >
            {option.count}
          </span>
        </div>
      )
    }

    if (multiOptionLabel) {
      return getSearchOptionLabel({ option, optionLabelKeys })
    } else {
      return getOptionLabel({
        option,
        transaction,
        investment,
        roles,
        keys: optionLabelKeys,
      })
    }
  }

  return (
    <Select
      defaultValue={selectedOption}
      value={selectedOption}
      onChange={setSelectedOption}
      options={optionList}
      getOptionLabel={customComponent}
      getOptionValue={option => option.id}
      isDisabled={isDisabled}
      isClearable={isClearable}
      inputValue={searchValue}
      onInputChange={setSearchValue}
    />
  )
}

ReactSelect.propTypes = {
  users: PropTypes.array,
  setSelectedOption: PropTypes.func,
  selectedOption: PropTypes.object,
  transaction: PropTypes.bool,
  roles: PropTypes.array,
  multiOptionLabel: PropTypes.bool,
  optionLabelKeys: PropTypes.array,
  investment: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isClearable: PropTypes.bool,
  searchValue: PropTypes.string,
  setSearchValue: PropTypes.func,
}
